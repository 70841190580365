import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'speiseplanGetTagesmenu'
})
export class SpeiseplanGetTagesmenuPipe implements PipeTransform {

  transform(value: number, arg: any, type: any): any {
    if (type == 'menu') {
      return arg.value.tagesMenues[value] || null;
    } else {
      return arg.bestellMenues[value] || null;
    }
  }

}
