import {AfterViewInit, Component, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { MandantenService } from 'services/mandanten/mandanten.service';
import { neuKunde } from 'services/interface/registrierung.interface'
import { MatDialog } from '@angular/material/dialog';
import {MatAccordion} from '@angular/material/expansion';
import { ConfirmDialogComponent } from '../../../../submodul/obs.shared/dialog/confirm-dialog/confirm-dialog.components';
import { RegistrierungFormularComponent } from '../../../../submodul/obs.registrierung/component/registrierung-formular/registrierung-formular.component';
import {SeitenleisteService} from '../../../../submodul/obs.seitenleiste/seitenleiste.service';
import {SeitenleisteInhalt} from '../../../../submodul/obs.seitenleiste/seitenleisteInhalt.class';
import {
  RegistrierungSeitenleisteComponent
} from '../../../../submodul/obs.seitenleiste/components/registrierung/registrierung.seitenleiste.component';
import {BehaviorSubject, Subscription} from 'rxjs';


@Component({
  selector: 'web-registrierung-form',
  templateUrl: './web-registrierung-form.component.html',
  styleUrls: ['./web-registrierung-form.component.css'],
})
export class WebRegistrierungFormComponent implements OnInit, AfterViewInit, OnDestroy {

	@Output() check: neuKunde
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild('formular') formular:RegistrierungFormularComponent

  constructor(
    private mandant_service: MandantenService,
    private seitenleistenService: SeitenleisteService,
		public dialog: MatDialog,
    public router: Router,
  ) { }

  public navigation = []
	public link:string
  public registrierung
  public vertrag
  public vertrag_methode
  public vertrags_titel
  public vertrags_text
  public vertrag_offen = true
  public mandant
  public seitenleisteSubscription: Subscription

  ngOnInit() {
    this.mandant_service.mandant_get().subscribe((mandant: any) => {
      this.mandant = mandant
      this.registrierung = mandant.darstellungRegistrierung
      this.vertrag = this.router.url.split('/')[2]
      this.vertrag_methode = mandant.registrierFormen[this.vertrag].registrierArt
      this.vertrags_titel = mandant.registrierFormen[this.vertrag]
      this.vertrags_text = atob(mandant.registrierFormen[this.vertrag].infoTextHTML)

      for (let link of Object.entries(mandant.navigation_root)){
        if (link[1] === true){
          if (link[0] == 'menu' && mandant.speisePlanIds[0]){
            this.link = "/#/speiseplan-mandant/" + mandant.speisePlanIds[0].spanId
          }else{
            this.link = "/#/home"
          }
        }
      }
      this.seitenleistenService.emitterSeitenleiste = new BehaviorSubject<any>(false)
      this.seitenleisteAktualisieren()
      this.seitenleisteSubscription = this.seitenleistenService.emitterSeitenleiste.subscribe(event => {
        if (event['navigation']){
          this.formular[event.navigation].expanded = true
        }
        if (event['formular'] && event['formular'] == 'absenden'){
          this.formular.onSubmit()
        }
        if (event['formular'] && event['formular'] == 'abbrechen'){
          this.verwerfen()
        }
        if (event['vertrag']){
          this.vertrag_zustand()
        }
      })
    })
  }

  ngAfterViewInit() {
    if (this.formular.vertragstext){
      this.vertrag_offen = this.formular.vertragstext.expanded;
    }
  }

  private seitenleisteAktualisieren(){
    this.seitenleistenService.updateSeitenleiste(new SeitenleisteInhalt(
      RegistrierungSeitenleisteComponent, {
        mandant: this.mandant,
        vertrag: this.mandant.registrierFormen[this.vertrag],
        vertragsZustand: this.vertrag_offen,
        registrierungsSchritt: 1
      })
    )
  }

  public vertrag_zustand(){
    if (this.formular.vertragstext.expanded){
      this.formular.vertragstext.expanded = false
      this.vertrag_offen = false
    } else {
      this.formular.vertragstext.expanded = true
      this.vertrag_offen = true
    }
    this.seitenleisteAktualisieren()
  }

  ngOnDestroy() {
    this.seitenleisteSubscription.unsubscribe()
  }

  public verwerfen(){
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: "Der Vorgang zur Neukundenregistrierung wird abgebrochen. <br> Alle eingetragenen Daten gehen verloren. <br> Möchten Sie die Eingaben verwerfen?",
        panelClass: 'dialog-container-bestellschluss',
      }
    }).afterClosed().subscribe((result) => {
      if (result){
        if (localStorage.getItem('formular')){localStorage.removeItem('formular')}
        if (this.mandant.darstellungRegistrierung == 2){
          this.router.navigate(['/register-zwischenseite'])
        }else{
          this.router.navigateByUrl('login')
        }
        if (localStorage.getItem('gruppen')) {localStorage.removeItem('gruppen')}
      }
    })
  }

  public scroll(divElement: HTMLElement) {
    divElement.scrollIntoView({ behavior: 'smooth' });
  }
}
