import { Component, OnInit } from '@angular/core';



@Component({
  selector: 'menuVorauswahlAiz',
  templateUrl: './aiz.component.html',
  styleUrls: ['./aiz.component.css'],
})

export class AizComponent implements OnInit {

  constructor(
  ) {
  }

  ngOnInit() {
  }
}
