import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { ApiCallService } from 'services/api/api-call.service';
import {zip} from 'rxjs';
import {DbReaderService} from '../db-reader/db-reader.service';

@Injectable()
export class StartupNavigationGuard  {
  constructor(
    private router: Router,
    private api_call: ApiCallService,
    private db_service: DbReaderService,
  ) { }

  canActivate(): boolean {
    let parameter = {
      mandantId: environment.mandant_id,
    }
    if (environment.type == 'web'){
       this.startseitenVerhaltenWbp(parameter)
    } else {
      this.startseitenVerhaltenWba(parameter)
    }
    return true;
  }

  public startseitenVerhaltenWbp(parameter){
    this.getNavigation(parameter)
  }

  public startseitenVerhaltenWba(parameter){
    const aktiverKunde = this.db_service.get('active', 'User')
    const kunden = this.db_service.all('User', 'keys')
    zip(aktiverKunde, kunden).subscribe((result) => {
      if (result[0] && result[1].length === 2) {
        this.wbaLoggedIn(parameter)
      } else {
        this.getNavigation(parameter)
      }
    })
  }

  public getNavigation(parameter){
    this.api_call.request('/public', 'mandant/get', parameter)
      .pipe(map((mandant:any) => { return mandant }))
      .subscribe(mandant => {
        let navigation = mandant.navigation_root_default
        if (navigation == 'home') {
          this.router.navigateByUrl('/home')
        } else if (navigation == 'login') {
          this.router.navigateByUrl('/login')
        } else {
          if (Object.keys(mandant.speisePlanIds).length < 4 || environment.type != 'web'){
            this.router.navigateByUrl('/speiseplan-mandant/' + Object.values(mandant.speisePlanIds)[0]['splanId'])
          } else {
            this.router.navigateByUrl('/speiseplan-overview')
          }
        }
      })
  }

  public wbaLoggedIn(parameter): void {
    console.log(parameter);
    this.api_call.request('/public', 'mandant/get', parameter)
      .pipe(map((mandant:any) => { return mandant }))
      .subscribe(mandant => {
        if (mandant.navigation_logged_in_default === 'home') {
          this.router.navigateByUrl('/home')
        } else if (mandant.navigation_logged_in_default === 'menu') {
          this.router.navigateByUrl('/speiseplan-kunde')
        }
      })
  }
}
