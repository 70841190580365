import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {Platform} from '@angular/cdk/platform';
import {environment} from 'environments/environment';
import {ApiCallService} from '../../../../services/api/api-call.service';
import {ContentBlockService} from '../../../../services/prozesse/contentBlock.service';

@Component({
  selector: 'speiseplanMenuBeschreibung',
  templateUrl: './speiseplanMenuBeschreibung.component.html',
  styleUrls: ['./speiseplanMenuBeschreibung.component.css'],
})
export class SpeiseplanMenuBeschreibungComponent implements OnInit {

  @Input('menu') menu
  @Input('mandant') mandant
  @Input('speiseplan') speiseplan
  @Input('preisAnzeige') preisAnzeige
  public menuAIZ
  public detectedPlattform: string
  public menuZusatz
  public verpackungsArten
  public verpackungsId
  public speiseplanId

  constructor(
    public dialog: MatDialog,
    public platform: Platform,
    public apiCallService: ApiCallService,
    public contentBlockService: ContentBlockService,
  ) {}

  ngOnInit() {
    if (this.mandant.sonderKonfiguration.Verpackungsarten){
      this.verpackungsArten = Object.values(this.mandant.sonderKonfiguration.Verpackungsarten)
      this.verpackungsId = Object.keys(this.verpackungsArten)[0]
      if (this.speiseplan.menutexte){
        this.speiseplanId = (this.speiseplan.menutexte[Object.keys(this.speiseplan.menutexte)[0]].splanId).toString()
      }
    }
    this.detectedPlattform = environment.type
    if (this.menu.allergene && this.menu.inhaltsstoffe && this.menu.zusatzstoffe){
      this.menuAIZ = [...this.menu.allergene, ...this.menu.inhaltsstoffe, ...this.menu.zusatzstoffe];
    }
    if (this.menu.allergeneIds && this.menu.inhaltsstoffeIds && this.menu.zusatzstoffeIds){
      this.menuAIZ = [...this.menu.allergeneIds, ...this.menu.inhaltsstoffeIds, ...this.menu.zusatzstoffeIds];
    }
    if (this.menu.menueText.includes('*')){
      this.menuZusatzContent()
    }
  }

  menuZusatzContent() {
    let command = this.detectedPlattform == 'web' ? 'Web_Menuetextergaenzung' : 'App_Menuetextergaenzung'
    this.contentBlockService.getContentBlock(command).subscribe((result:any) => {
      this.menuZusatz = atob(result[0].content)
    })
  }

  protected readonly environment = environment;
}
