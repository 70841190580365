import {Component, OnInit, ViewChild} from '@angular/core';
import { AppStateService } from 'services/app/app-state.service';
import {MandantenService} from 'services/mandanten/mandanten.service';
import {UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiNeuKundeClass} from '../../../../services/prototype/apiNeuKunde.class';
import {KundeService} from '../../../../services/kunden/kunde.service';
import {MitteilungsService} from '../../../../services/prozesse/mitteilung.service';
import {zip} from 'rxjs';
import {RegistrierungService} from '../../../../services/registrierung/registrierung.service';
import {ConfirmDialogComponent, ConfirmDialogModel} from '../../../obs.shared/dialog/confirm-dialog/confirm-dialog.components';
import {MatDialog} from '@angular/material/dialog';
import {Platform} from '@angular/cdk/platform';
import {environment} from 'environments/environment';

@Component({
  selector: 'app-registrierung-formular',
  templateUrl: './registrierung-formular.component.html',
  styleUrls: ['./registrierung-formular.component.css']
})
export class RegistrierungFormularComponent implements OnInit {

  @ViewChild('vertragsText') vertragstext
  @ViewChild('vertragspartner') vertragspartner
  @ViewChild('essensteilnehmer') essensteilnehmer
  @ViewChild('rechnungskunde') rechnungskunde
  @ViewChild('bankverbindung') bankverbindung
  @ViewChild('zahlung') zahlung
  @ViewChild('einrichtung') einrichtung
  @ViewChild('anmeldung') anmeldung
  @ViewChild('zusatz') zusatz
  public detectedPlattform: string
  public vertragsId: string
  public mandant
  public formGroup
  public agb
  public datenschutz
  public widerruf
  public gesetzestexte = []
  public enviroment = environment
  public feldConfig
  public gruppenfreitext: boolean

  constructor(
    public platform: Platform,
    private appStateService: AppStateService,
    private mandantService: MandantenService,
    private aktivierteRoute: ActivatedRoute,
    private router: Router,
    private registrierungsService: RegistrierungService,
    private kundeService: KundeService,
    private mittteilungsService: MitteilungsService,
    private matDialog: MatDialog,
  ) {
    this.appStateService.set_page_name('Neukundenregistrierung');
    this.appStateService.set_seitenname('registrierungsFormular')
  }

  ngOnInit(): void {
    if (environment.type == 'web') {
      this.detectedPlattform = 'WEB'
    }
    zip(this.aktivierteRoute.paramMap, this.mandantService.mandant_get()).subscribe((daten:any) => {
      this.feldConfig = this.registrierungsService.erstelleFormularConfig(daten[0].params.id, daten[1])
      this.mandant = daten[1]
      this.gruppenfreitext = this.mandant.sonderKonfiguration.gruppenfreitext
      this.vertragsId = daten[0].params['id']
      const formFelder = {}
      if (localStorage.getItem('registrierungForm')){
        const gepeichertesFormular = JSON.parse(localStorage.getItem('registrierungForm'))
        for (let feld in gepeichertesFormular){
          formFelder[feld] = new UntypedFormControl(gepeichertesFormular[feld], [])
        }
      }else{
        for (const gruppe in this.feldConfig) {
          for (let feld in this.feldConfig[gruppe]){
            formFelder[feld] = new UntypedFormControl(this.feldConfig[gruppe][feld].value, [])
          }
        }
      }
      this.formGroup = new UntypedFormGroup(formFelder)
      if (this.gruppenfreitext === true) {
        this.formGroup.addControl('gruppenfreitextInput', new UntypedFormControl(''));
      }
      this.agb = {name: 'Allgemeine Geschäftsbedingungen', typ: 'agb', text: daten[1].betreiberDaten.agb}
      this.datenschutz = {name: 'Datenschutzbestimmung', type: 'datenschutz', text: daten[1].betreiberDaten.datenschutz}
      this.widerruf = {name: 'Widerrufserklärung', type: 'widerruf', text: daten[1].betreiberDaten.widerruf}
      let ges_alt = [this.agb, this.datenschutz, this.widerruf]
      for (let [pos, inhalt] of ges_alt.entries()) {
        if (inhalt.text != "") {
          this.gesetzestexte.push(ges_alt[pos])
        }
      }
    })
  }

  public verlinkungZumLogin() {
    const dialogData = new ConfirmDialogModel("Confirm Action", `Der Vorgang zur Neukundenregistrierung wird abgebrochen. Alle eingetragenen Daten gehen verloren. Möchten Sie die Eingaben verwerfen?`);
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult){
        localStorage.removeItem('registrierungForm')
        if (this.mandant.darstellungRegistrierung == 2){
          this.router.navigateByUrl('register-zwischenseite')
        }else{
          this.router.navigateByUrl('login')
        }
      }
    });
  }

  public onKarteAufklappen(event){
    // console.log(event)
    // event.target.scrollTop({behavior: 'smooth'})
  }

  public onSubmit() {
    localStorage.setItem('registrierungForm', JSON.stringify(this.formGroup.value))
    const neuKunde = new ApiNeuKundeClass(this.vertragsId, this.formGroup.value)
    this.kundeService.kunde_validate(JSON.stringify(neuKunde)).subscribe(
      next => {
        if (this.detectedPlattform != 'WEB'){
          this.router.navigate(['register-abschluss', this.vertragsId])
        }
        else {
          this.router.navigate(['register-abschluss', this.vertragsId])
        }
      },
      error => {
        if (error.error.content.msgId == 99002){
          if (this.detectedPlattform != 'WEB'){
            this.mittteilungsService.set_snackbar('99002_A', 5000)
          }
          Object.entries(error.error.content.params).forEach(fehler => {
            this.formGroup.controls[fehler[0]].setErrors(this.mittteilungsService.checkMitteilung(fehler[1]).content)
          })
          this.fehlerBehandlung()
        }
      }
    )
  }

  private fehlerBehandlung(){
    for (let gruppe in this.feldConfig){
      let error = 0
      for (let feldName in this.feldConfig[gruppe]){
        if (this.formGroup.controls[feldName].errors){
          error ++
        }
      }
      const kartenKopf = document.getElementById(`${gruppe}Karte`)
      if (error > 0){
        kartenKopf.style.color = 'var(--bad)'
      }else{
        kartenKopf.style.color = 'var(--primary)'
      }
    }
  }
}
