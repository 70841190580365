<div *ngIf="tag" id="speiseplanTagLabel" speiseplanLabel [speiseplan]="speiseplan" [tag]="tag.value" [darstellung]="darstellung" [class.vertikal]="darstellung == 1 && !mobil">
  <div class="speiseplanTagLabelNormal">
    <strong>{{ tag.key | date: 'dd.MM.YY' }} - {{ tag.key | datum:'wochentag' }}</strong>
  </div>
  <div class="speiseplanTagLabelInfo"
    *ngIf="tag.value.feiertag || tag.value.zustellPause"
    [class.feiertag]="tag.value.feiertag"
    [class.zustellpause]="tag.value.zustellPause"
    [class.alle]="tag.value.feiertag && tag.value.zustellPause"
    (click)="openDialog()"
  >
    <mat-icon>info</mat-icon>
    <ng-container *ngIf="darstellung == 2">
      <strong *ngIf="tag.value.zustellPause && !mobil">Zustellpause</strong>
      <strong *ngIf="tag.value.feiertag && tag.value.zustellPause && !mobil">& </strong>
      <strong *ngIf="tag.value.feiertag && !mobil">{{ tag.value.feiertag.bezeichnung }}</strong>
    </ng-container>
  </div>
  <div id="speiseplanTagLabelPlatzhalter"></div>
</div>
