import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'menuVorauswahlTag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.css'],
})

export class TagComponent implements OnInit {

  @Input('mandant') mandant
  @Input('tag') tag

  constructor() {
  }

  ngOnInit() {}
}
