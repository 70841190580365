import {Injectable } from '@angular/core';
import {BehaviorSubject, of} from 'rxjs';

import mitteilungen_config from 'configs/mitteilungen.config.json';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackbarComponent} from '../../submodul/obs.shared/fragmente/snackbar/snackbar.component';

@Injectable()
export class MitteilungsService {

  public mitteilungen = new BehaviorSubject<any>(false)
  public benachrichtigungen = new BehaviorSubject<any>(false)

  constructor(
    public snackbar: MatSnackBar,
  ) {
    if (localStorage.getItem('benachrichtigung') == 'true') {
      this.benachrichtigungen.next(true)
    }
  }

  public set_snackbar(code: string, dauer = 5000) {
    let mitteilung = mitteilungen_config.filter((element => {
      if (element.id == code)
        return element.content
    }))
    this.snackbar.openFromComponent(SnackbarComponent, {
      data: mitteilung,
      horizontalPosition: 'left',
      verticalPosition: 'bottom',
      duration: dauer,
      panelClass: ['snackbar', `${mitteilung[0].typ}`]
    })
  }

  public setMitteilung(value) {
    value = mitteilungen_config.filter((element => {
      if (element.id == value)
        return element
    }))
    this.mitteilungen.next(value[0])
  }

  public checkMitteilung(value){
    value = mitteilungen_config.filter((element => {
      if (element.id == value)
        return element
    }))
    return value[0]
  }
}

export class Mitteilung{
  public code:string
  public content:string
  public dauer: number

  constructor(code, content, dauer){
    this.code = code
    this.content = content
    this.dauer = dauer
  }
}
