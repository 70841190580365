import { Component, OnInit, ViewChild} from '@angular/core';
import { FormDatenService } from 'services/prozesse/form-daten.service';
import { MandantenService } from 'services/mandanten/mandanten.service';
import { KundeService } from 'services/kunden/kunde.service';
import { DialogService } from 'services/prozesse/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import {ConfirmDialogComponent} from '../../../../submodul/obs.shared/dialog/confirm-dialog/confirm-dialog.components';
import {Router} from '@angular/router';
import {SeitenleisteInhalt} from '../../../../submodul/obs.seitenleiste/seitenleisteInhalt.class';
import {
  RegistrierungSeitenleisteComponent
} from '../../../../submodul/obs.seitenleiste/components/registrierung/registrierung.seitenleiste.component';
import {SeitenleisteService} from '../../../../submodul/obs.seitenleiste/seitenleiste.service';
import {BehaviorSubject, Subscription} from 'rxjs';

@Component({
  selector: 'web-registrierung-abschluss',
  templateUrl: './web-registrierung-abschluss.component.html',
  styleUrls: ['./web-registrierung-abschluss.component.css'],
})
export class WebRegistrierungAbschlussComponent implements OnInit {

  @ViewChild('formular') formular
  constructor(
    public dialog_service: DialogService,
    private mandant_service: MandantenService,
    private form_daten_service: FormDatenService,
    public kunde_service: KundeService,
    private dialog: MatDialog,
    public router: Router,
    private seitenleistenService: SeitenleisteService,
  ) {}

  public form_daten
  public vertrag
  public vertrag_titel
  public vertrag_methode
  public vertrags_text
  public eingabe_felder
  public mandant
  public caterer
  public kunde
  public gruppe
  public mailer
  public passwort
  public passwort_wiederholen
  public seitenleisteSubscription: Subscription

  ngOnInit() {
    this.mandant_service.mandant_get().subscribe((mandant: any) => {
      this.mandant = mandant
      this.caterer = mandant.betreiberDaten
      this.mailer = mandant.mailerAktiv
      this.vertrag = this.router.url.split('/')[2]
      this.vertrag_titel = mandant.registrierFormen[this.vertrag].name
      this.vertrag_methode = mandant.registrierFormen[this.vertrag].registrierArt
      this.vertrags_text = atob(mandant.registrierFormen[this.vertrag].infoTextHTML)
      this.eingabe_felder = mandant.registrierFormen[this.vertrag].eingabeFelder
      this.form_daten = this.form_daten_service.form
      this.passwort = atob(this.form_daten_service.form.passwort)
      this.passwort_wiederholen = atob(this.form_daten_service.form.passwort2)
      this.seitenleistenService.emitterSeitenleiste = new BehaviorSubject<any>(false)
      this.seitenleisteAktualisieren()
      this.seitenleisteSubscription = this.seitenleistenService.emitterSeitenleiste.subscribe(event => {
        if (event['navigation']){
          var url = location.href;
          location.href = "#"+event.navigation;
          history.replaceState(null,null,url);
        }
        if (event['formular'] && event['formular'] == 'absenden'){
          this.formular.kundeCommitApi()
        }
        if (event['formular'] && event['formular'] == 'bearbeiten'){
          this.router.navigateByUrl(`/register-form/${this.vertrag}`)
        }
        if (event['formular'] && event['formular'] == 'abbrechen'){
          this.verwerfen()
        }
      })
    })
    if (window.localStorage.getItem('gruppen')){
      this.gruppe = JSON.parse(window.localStorage.getItem('gruppen'))
    }
  }

  private seitenleisteAktualisieren(){
    this.seitenleistenService.updateSeitenleiste(new SeitenleisteInhalt(
      RegistrierungSeitenleisteComponent, {
        mandant: this.mandant,
        vertrag: this.mandant.registrierFormen[this.vertrag],
        vertragsZustand: true,
        registrierungsSchritt: 2
      })
    )
  }

  public verwerfen() {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: "Der Vorgang zur Neukundenregistrierung wird abgebrochen. <br> Alle eingetragenen Daten gehen verloren. <br> Möchten Sie die Eingaben verwerfen?",
        panelClass: 'dialog-container-bestellschluss',
      }
    }).afterClosed().subscribe((result) => {
      if (result) {
        if (localStorage.getItem('gruppen')) {
          localStorage.removeItem('gruppen')
        }
        if (localStorage.getItem('formular')) {
          localStorage.removeItem('formular')
        }
        if (this.mandant.darstellungRegistrierung == 2){
          this.router.navigateByUrl('register-zwischenseite')
        }else{
          this.router.navigateByUrl('login')
        }
      }
    })
  }
}
