import {Injectable} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {MatDialog} from '@angular/material/dialog';
import {MobilDialogUpdateComponent} from '../../components/mobil/mobil-dialog-update/mobil-dialog-update.component';

@Injectable()
export class ServiceWorkerService {

  public isPWA = window.matchMedia('(display-mode: standalone)').matches ? true : false
  public isUpdateCheck: boolean = false
  public updateCheck: boolean = false

  constructor(
    public updates: SwUpdate,
    private matDialog: MatDialog
  ) {
    this.serviceWorkerUpdate()
  }

  public serviceWorkerStatus() {
    return this.updates.isEnabled
  }

  public serviceWorkerUpdate() {
    if (this.updates.isEnabled) {
      this.updates.checkForUpdate().then((event) => {
        if (event){
          this.updates.versionUpdates.subscribe(event => {
            if (event.type === 'VERSION_READY' && !this.isUpdateCheck) {
              this.isUpdateCheck = true
              const dialogSubscription = this.matDialog.open(MobilDialogUpdateComponent)
              dialogSubscription.afterClosed().subscribe((result) => {
                if (result.neuladen) {
                  this.isUpdateCheck = false
                  location.reload();
                }
              })
            }
          })
        }
      })
    }
  }
}

