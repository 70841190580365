import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-confirm-routing-dialog',
  templateUrl:  './confirm-routing-dialog.components.html',
  styleUrls: ['./confirm-routing-dialog.components.css']
})
export class ConfirmRoutingDialogComponent implements OnInit {
  title: string;
  firma: string;
  link: string

  constructor(public dialogRef: MatDialogRef<ConfirmRoutingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmRoutingDialogModel) {
    // Update view with given values
    this.title = data.title;
    this.firma = data.firma;
    this.link = data.link
  }

  ngOnInit() {
  }

  onConfirm(): void {
    window.localStorage.removeItem('formular')
    this.dialogRef.close(true);
    window.location.href = this.link
  }
}

export class ConfirmRoutingDialogModel {

  constructor(public title: string, public firma: string, public link: string) {
  }
}
