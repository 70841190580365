<div *ngIf="mitteilungen" id="mitteilungen">
  <ng-container *ngIf="data.vorhanden">
    <div id="globalMessage" *ngIf="mitteilungen.global">
      <div class="messageContainer">
        <strong>Wichtige Ankündigung</strong>
        <mat-icon *ngIf="neu['global']" class="mitteilungenIconWichtig">warning</mat-icon>
      </div>
      <span [innerHTML]="mitteilungen.global"></span>
    </div>

    <div id="mandantenMessage" *ngIf="mitteilungen.mandant">
      <div class="messageContainer">
        <strong>Nachricht Ihres Caterers</strong>
        <mat-icon *ngIf="neu['mandant']" class="mitteilungenIconWichtig">warning</mat-icon>
      </div>
      <span [innerHTML]="mitteilungen.mandant"></span>
    </div>

    <div id="einrichtungsMessage" *ngIf="mitteilungen.einrichtung && kunde">
      <div class="messageContainer">
        <strong>Nachricht Ihrer Einrichtung {{ mitteilungen.einrichtung.nameEinrichtung }}</strong>
        <mat-icon *ngIf="neu['einrichtung']" class="mitteilungenIconWichtig">warning</mat-icon>
      </div>
      <span [innerHTML]="mitteilungen.einrichtung.info | bbCode"></span>
    </div>
  </ng-container>

  <ng-container *ngIf="!data.vorhanden">
    <div id="keineMitteilungen">
      <div class="messageContainer">
        <strong>Information</strong>
      </div>
      <span>Zurzeit liegen keine neuen Mitteilungen vor.</span>
    </div>

  </ng-container>

  <button mat-button mat-dialog-close (click)="mitteilungenService.mitteilungsZustandSichern()">Mitteilungen gelesen</button>
</div>
