import {Component, Inject} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'web-dialog',
  templateUrl: './web-dialog.component.html',
  styleUrls: ['./web-dialog.component.css']
})
export class WebDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
		public dialog: MatDialog,
  ) { }

  public closeDialog(){
    this.dialog.closeAll()
  }

}
