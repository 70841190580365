import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MenuVorauswahlService} from './menuVorauswahl.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    MenuVorauswahlService
  ],
  declarations: []
})
export class MenuVorauswahlServicesModule {}
