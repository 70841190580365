import {Injectable} from '@angular/core';
import {MandantenService} from '../../../services/mandanten/mandanten.service';
import {SpeiseplanService} from './speiseplan.service';
import {MitteilungsService} from '../../../services/prozesse/mitteilung.service';
import {MenueSyncroSopro} from '../../../services/sopro/menue-syncro.sopro';


@Injectable()
export class SpeiseplanValidationService {

  constructor(
    private mandantService: MandantenService,
    private speiseplanService: SpeiseplanService,
    private mitteilungsService: MitteilungsService,
    private menueSyncro: MenueSyncroSopro,
  ) {}

  public menu_zuordnung(aktuelle_bestellung, bestellungen) {
    let aktuelle_regel = this.speiseplanService.config.getValue().bestellungen[aktuelle_bestellung.menueTextId]
    if (aktuelle_regel.zuordnung.length > 0) {
      let valid = false
      let zuordnungen = Object.values(bestellungen.bestellMenues).filter((bestellung: any) => {
        let regel = this.speiseplanService.config.getValue().bestellungen[bestellung.menueTextId]
        if (regel.gruppe == aktuelle_regel.zuordnung[0]) {
          if (bestellung.mengeNeu > 0) {
            valid = true
            return false
          }
          return true
        }
      })
      if (!valid) {
        let _config = this.speiseplanService.config.getValue()
        _config.bestellungen[aktuelle_bestellung.menueTextId].fehler = true
        this.mitteilungsService.setMitteilung('6003')
        for (let bestellung of <any>zuordnungen) {
          _config.bestellungen[bestellung.menueTextId].markierung = true
        }
        this.speiseplanService.config.next(_config)
      } else {
        aktuelle_bestellung.error = false
        let _config = this.speiseplanService.config.getValue()
        _config.bestellungen[aktuelle_bestellung.menueTextId].fehler = false
        this.speiseplanService.config.next(_config)
      }
    }
    return aktuelle_bestellung
  }

  public menu_zuordnung_2(aktuelle_bestellung, bestellungen) {
    let aktuelle_regel = this.speiseplanService.config.getValue().bestellungen[aktuelle_bestellung.menueTextId]
    if (aktuelle_regel.gruppe > 0 && aktuelle_bestellung.mengeNeu == 0) {
      let zuordnungen = Object.values(bestellungen.bestellMenues).filter((bestellung: any) => {
        let regel = this.speiseplanService.config.getValue().bestellungen[bestellung.menueTextId]
        if (regel.zuordnung[0] == aktuelle_regel.gruppe && bestellung.mengeNeu > 0) {
          return true
        }
      })
      if (zuordnungen.length > 0) {
        let _config = this.speiseplanService.config.getValue()
        for (let bestellung of <any>zuordnungen) {
          bestellung.mengeNeu = 0
        }
        this.speiseplanService.config.next(_config)
      }
    }
    return aktuelle_bestellung
  }

  public menu_gruppen(aktuelle_bestellung, bestellungen) {
    this.mandantService.mandant_get().subscribe(mandant => {
      let aktuelle_regel = this.speiseplanService.config.getValue().bestellungen[aktuelle_bestellung.menueTextId]
      let alle_regeln = []
      if (aktuelle_regel.gruppe > 0) {
        for (let menu_id in bestellungen.bestellMenues) {
          if (bestellungen.bestellMenues[menu_id].menueId !== aktuelle_bestellung.menueId) {
            let bestellung = bestellungen.bestellMenues[menu_id]
            let regeln = this.speiseplanService.config.getValue().bestellungen[bestellung.menueTextId]
            alle_regeln.push(regeln)
            if (aktuelle_regel.gruppe == regeln.gruppe && !regeln.mehrfachbestellbar) {
              bestellung.mengeNeu = 0
            }
          }
        }
      }
      if (mandant.sonderKonfiguration.Bestellung_mengenSyncByGrp[aktuelle_regel.splanId]) {
       this.menueSyncro.mengenSyncByGrp(aktuelle_regel, bestellungen, alle_regeln)
      }
      return aktuelle_bestellung
    })

  }

}

