<div id="menuvorauswahlKarte" (click)="menuVorauswahlDetails($event)" >
<!--  <div class="speiseplan-menu-titel">-->
<!--    <strong>{{ menuInformationen.bezeichnung }}</strong>-->
<!--  </div>-->
  <div class="menuvorauswahlKarteContent">
    <div class="tooltip" *ngIf="menu.menueText !== ''">
      <div
        id="speiseplanMenuBeschreibung"
      >
      </div>
      <span class="tooltiptext" [innerHTML]="menuInformationen.menueText | bbCode"></span>
    </div>
    <div *ngFor="let menuKomponenten of menu.optionen | keyvalue">
      <span *ngIf="tag.vorausgewaehlt.menues[menu.menueNr][menuKomponenten.value.pos]"
            [innerHTML]="tag.vorausgewaehlt.menues[menu.menueNr][menuKomponenten.value.pos] | menuVorauswahlMenuname:tag | bbCode">
      </span>
    </div>
  </div>
</div>
