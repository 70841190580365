import {Component, Input, AfterViewInit} from '@angular/core';
import {SpeiseplanSondertagComponent} from '../speiseplanZustellpause/speiseplanSondertag.component';
import {MatDialog} from '@angular/material/dialog';
import {environment} from '../../../../environments/environment';
import {Router} from '@angular/router';

@Component({
  selector: 'speiseplanTagLabel',
  templateUrl: './speiseplanTagLabel.component.html',
  styleUrls: ['./speiseplanTagLabel.component.css'],
})
export class SpeiseplanTagLabelComponent implements AfterViewInit {

  @Input('tag') tag
  @Input('speiseplan') speiseplan
  @Input('darstellung') darstellung

  constructor(
    public dialog: MatDialog,
    private router: Router,
  ) {
  }

  public menueAnzahl
  public mobil = false

  ngAfterViewInit() {
    this.mobil = environment.type != 'web' ? true : false
    if (this.router.url == '/warenkorb'){
      this.menueAnzahl = Object.keys(this.tag.value.bestellMenues).length
    } else {
      this.menueAnzahl = Object.keys(this.tag.value.tagesMenues).length
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(SpeiseplanSondertagComponent, {})
    dialogRef.componentInstance.feiertag = this.tag.value.feiertag
    dialogRef.componentInstance.zustellpause = this.tag.value.zustellPause
  }
}
