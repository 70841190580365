import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Platform} from '@angular/cdk/platform';
import {environment} from 'environments/environment';
import {MenuVorauswahlService} from '../../services/menuVorauswahl.service';

@Component({
  selector: 'menuVorauswahlMenukarte',
  templateUrl: './menuVorauswahlMenukarte.component.html',
  styleUrls: ['./menuVorauswahlMenukarte.component.css'],
})
export class MenuVorauswahlMenukarteComponent implements OnInit {

  @Input('mandant') mandant
  @Input('tag') tag
  @Input('menu') menu
  public menuInformationen
  public hauptmenuIndex
  public buffer
  public aktuelleMenuVorauswahl

  constructor(
    public dialog: MatDialog,
    public platform: Platform,
    public menuVorauswahlService: MenuVorauswahlService,
  ) {}

  ngOnInit() {
    this.hauptmenuIndex = this.tag.vorausgewaehlt.menues[this.menu.menueNr]['1']
    this.menuInformationen = this.tag.tagesMenues[this.hauptmenuIndex.replace('M', '')]
    this.aktuelleMenuVorauswahl = this.menuVorauswahlService.menuVorauswahl.getValue()
    this.buffer = { ...this.aktuelleMenuVorauswahl[this.tag.datum].vorausgewaehlt}
  }

  public menuVorauswahlDetails(event){
    this.menuVorauswahlService.updateMenuVorauswahlSeitenleiste(this.mandant, this.menuInformationen, this.buffer, this.menu, this.hauptmenuIndex, this.tag)
  }

  protected readonly environment = environment;
}
