import {Component, OnInit} from '@angular/core';
import {MandantenService} from '../../../../services/mandanten/mandanten.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiNeuKundeClass} from '../../../../services/prototype/apiNeuKunde.class';
import {KundeService} from '../../../../services/kunden/kunde.service';
import { AppStateService } from 'services/app/app-state.service';
import {RegistrierungService} from '../../../../services/registrierung/registrierung.service';
import {zip} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent, ConfirmDialogModel} from '../../../obs.shared/dialog/confirm-dialog/confirm-dialog.components';
import {RegistrierungErfolgreichComponent} from '../registrierung-erfolgreich/registrierung-erfolgreich.component';
import {Platform} from '@angular/cdk/platform';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-registrierung-zusammenfassung',
  templateUrl: './registrierung-zusammenfassung.component.html',
  styleUrls: ['./registrierung-zusammenfassung.component.css']
})
export class RegistrierungZusammenfassungComponent implements OnInit {

  public vertragsId
  public mandant
  public formData
  public formConfig
  public detectedPlattform: string
  public enviroment = environment
  public gruppenfreitext: boolean


  constructor(
    public platform: Platform,
    private mandantService: MandantenService,
    private aktivierteRoute: ActivatedRoute,
    private router: Router,
    private kundeService: KundeService,
    private appStateService: AppStateService,
    private registrierungsService: RegistrierungService,
    private matDialog: MatDialog,
  ) {
    this.appStateService.set_page_name('Abschluss der Registrierung');
    this.appStateService.set_seitenname('registrierungsZusammenfassung')
  }

  ngOnInit(): void {
    if (this.platform.ANDROID) {
      this.detectedPlattform = 'ANDROID'
    }
    else if (this.platform.IOS) {
      this.detectedPlattform = 'IOS'
    }
    else {
      this.detectedPlattform = 'WEB'
    }
    zip(this.aktivierteRoute.paramMap, this.mandantService.mandant_get()).subscribe((data: any) => {
      this.vertragsId = data[0].params['id']
      this.formConfig = this.registrierungsService.erstelleFormularConfig(this.vertragsId, data[1])
      this.mandant = data[1]
    })
    this.formData = JSON.parse(localStorage.getItem('registrierungForm'))
  }

  public verlinkungZumLogin() {
    const dialogData = new ConfirmDialogModel("Confirm Action", `Der Vorgang zur Neukundenregistrierung wird abgebrochen. Alle eingetragenen Daten gehen verloren. Möchten Sie die Eingaben verwerfen?`);
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult){
        localStorage.removeItem('registrierungForm')
        if (this.mandant.darstellungRegistrierung == 2){
          this.router.navigateByUrl('register-zwischenseite')
        }else{
          this.router.navigateByUrl('login')
        }
      }
    });
  }

  public verlinkungZumFormular() {
    this.router.navigate(['register-form', this.vertragsId])
  }

  public kundeCommitApi() {
    const neuKunde = new ApiNeuKundeClass(this.vertragsId, this.formData)
    this.kundeService.kunde_commit(JSON.stringify(neuKunde)).subscribe((next) => {
      this.matDialog.open(RegistrierungErfolgreichComponent, {
        data: this.mandant,
        disableClose: true
      });
      },
      error => { console.log(error) }
    )
  }

}
