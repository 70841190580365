import { AfterViewInit, Directive, ElementRef, Input, OnChanges, Renderer2 } from "@angular/core";

@Directive({
  selector: '[speiseplan-darstellung]'
})
export class SpeiseplanDarstellungDirective implements AfterViewInit, OnChanges{
  @Input() speiseplan
  @Input() darstellung
  @Input() modus
  @Input() tag

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    ) {}

  ngAfterViewInit(){
    if (this.modus == 1 || this.modus == 2){
      this.modus = 'woche'
    }
    else if (this.modus == 3){
      this.modus = 'monat'
    }
    if (Object.keys(this.speiseplan.menu).length > 0){
      this._speiseplan_modus()
    }
    this._speiseplan_tage()
  }


  ngOnChanges(){
    if (Object.keys(this.speiseplan.menu).length > 0){
      this._speiseplan_modus()
    }
    this._speiseplan_tage()
  }

  _speiseplan_modus(){
    this.element.nativeElement.classList = ['speiseplan-kunde-grid']
    let laenge = Object.keys(this.tag).length
    if (this.modus == 'woche' || this.modus == 1 || this.modus == 2){
      if (this.darstellung == 1){
        this.element.nativeElement.classList.add(laenge < 7 ? 'vertical1' : 'vertical2')
      }else{
        this.element.nativeElement.classList.add('horizontal')
      }
    }
    if (this.modus == 'monat' || this.modus == 3){
      this.element.nativeElement.classList.add('horizontal')
    }
  }

  _speiseplan_tage(){
    if (Object.keys(this.speiseplan.menu).length == 0){
      if (!document.getElementById('speiseplan-leer')){
        let div = this.renderer.createElement('div')
        this.renderer.addClass(div, 'speiseplan-leer-message')
        this.renderer.addClass(div, 'flip-in-hor-bottom')
        this.renderer.setAttribute(div, 'id', 'speiseplan-leer')
        let img = this.renderer.createElement('mat-icon')
        this.renderer.addClass(img, 'mat-icon');
        this.renderer.addClass(img, 'material-icons');
        this.renderer.appendChild(img, this.renderer.createText('no_meals'))
        this.renderer.appendChild(div, img)
        this.renderer.appendChild(this.element.nativeElement, div)
      }
      this.element.nativeElement.classList.add('leer')
    }
    if (Object.keys(this.speiseplan.menu).length > 0 && document.getElementById('speiseplan-leer')){
      this.renderer.removeChild(this.element.nativeElement, document.getElementById('speiseplan-leer'));
    }
  }
}
