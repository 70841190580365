import {AfterViewInit, Component, OnInit} from '@angular/core';
import {MenuVorauswahlService} from '../../../obs.menuVorauswahl/services/menuVorauswahl.service';

@Component({
	selector: 'menuVorauswahl-seitenleiste',
	templateUrl: './menuVorauswahl.seitenleiste.component.html',
	styleUrls: ['./menuVorauswahl.seitenleiste.component.css'],
})
export class MenuVorauswahlSeitenleisteComponent implements OnInit, AfterViewInit {

  public mandant
  public menu
  public neben

  constructor(
    private menuVorauswahlService: MenuVorauswahlService,
	) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  speichereVorauswahl(){
    let aktuelleMenuVorauswahl = this.menuVorauswahlService.menuVorauswahl.getValue()
    this.menuVorauswahlService.menuVorauswahlCommit(aktuelleMenuVorauswahl)
  }

  protected readonly Object = Object;
}
