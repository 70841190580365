import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {BehaviorSubject} from 'rxjs';


@Injectable()
export class SpeiseplanEinzelbestellerService {

  public os
  public bearbeitet : BehaviorSubject<any> = new BehaviorSubject([])

  constructor() {
    this.os = environment.type == 'web' ? 'web': 'app'
  }

  bestellungErkennen(tag){
    let bestellung: any
    let status = false
    for (bestellung of Object.values(tag.bestellMenues)){
      if (bestellung.mengeAlt > 0) {
        status = true
      }
    }
    console.log(status)
    return status
  }

  statusTag(menue, bestelltag){
    let bearbeiteteTage = this.bearbeitet.getValue()
    if (bearbeiteteTage){
      if (!bearbeiteteTage.includes(bestelltag.datum)){
        bearbeiteteTage.push(bestelltag.datum)
      }
    }else{
      bearbeiteteTage = []
    }
    let bestellung
    let stati = false
    for (bestellung of Object.values(bestelltag.bestellMenues)){
      if (bestellung.mengeNeu != bestellung.mengeAlt){
        stati = true
        break;
      }
    }
    if (stati){
      if (!bearbeiteteTage.includes(bestelltag.datum)){
        bearbeiteteTage.push(bestelltag.datum)
      }
    } else {
      bearbeiteteTage = bearbeiteteTage.filter(datum => datum != bestelltag.datum)
    }
    this.bearbeitet.next(bearbeiteteTage)
  }
}

