import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable()
export class ApiCallService {

  private connection = {
    url: environment.url,
    version: 'API_1_4'
  }

  constructor(
    private http: HttpClient
  ) { }

  public request(endpoint, command, parameter) {

    let payload = {
      command: command,
      client: environment.type,
      parameter: parameter
    }

    if (endpoint == '/call' || endpoint == '/callV2' || endpoint == '/session-update') {
      payload['token'] = localStorage.getItem(localStorage.getItem('active'))
    }

    let options = {}
    if (endpoint == '/img' || endpoint == '/file') {
      options['responseType'] = 'blob'
    }
    return this.http.post(this.connection.url + endpoint, payload, options)
  }
}
