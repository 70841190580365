import {Component, Input, OnInit} from '@angular/core';
import {MenuVorauswahlService} from '../../services/menuVorauswahl.service';



@Component({
  selector: 'menuVorauswahlAuswahl',
  templateUrl: './auswahl.component.html',
  styleUrls: ['./auswahl.component.css'],
})

export class AuswahlComponent implements OnInit {

  @Input('Id') public id
  @Input('tag') public tag
  public menu

  constructor(
    private menuVorauswahlService: MenuVorauswahlService
  ) {
  }

  ngOnInit() {
    let menuTag = this.menuVorauswahlService.menuVorauswahl.getValue()
    this.menu = menuTag[this.tag].tagesMenues[this.id]
  }

  public vorauswahl(){
    // const vorauswahl = this.menuVorauswahlService.bearbeitetesMenu.getValue()
    // const index = vorauswahl.indexOf(this.menu)
    // if (index > -1){
    //   vorauswahl.splice(index, 1)
    // } else {
    //   vorauswahl.push(this.menu)
    // }
  }

  public aktivesMenue(menu) {
    // let aktivesmenu:any = document.querySelectorAll('.aktiv')
    // if (aktivesmenu){
    //   aktivesmenu.forEach(div => {
    //     div.classList.remove('aktiv')
    //   })
    // }
    // menu.srcElement.classList.add('aktiv')
    // this.menuVorauswahlService.aktivesMenue.next([this.tag, this.menu])
  }
}
