<div id="rechtsKontent">
  <div id="rechtlich" *ngIf="mandant">
    <button
      routerLink="/rechtliches"
      [queryParams]="{id: 'AGB'}"
      [class.active]="aktuelleUrl == 'AGB'"
      *ngIf="mandant.betreiberDaten.agb"
    >
      <mat-icon aria-label="AGB">fact_check</mat-icon>
      <span>AGB</span>
    </button>
    <button
      routerLink="/rechtliches"
      [queryParams]="{id: 'Datenschutz'}"
      [class.active]="aktuelleUrl == 'Datenschutz'"
      *ngIf="mandant.betreiberDaten.datenschutz"
    >
      <mat-icon aria-label="Datenschutz">security</mat-icon>
      <span>Datenschutz</span>
    </button>
    <button
      routerLink="/rechtliches"
      [queryParams]="{id: 'Widerruf'}"
      [class.active]="aktuelleUrl == 'Widerruf'"
      *ngIf="mandant.betreiberDaten.widerruf"
    >
      <mat-icon aria-label="Widerruf">pan_tool</mat-icon>
      <span>Widerruf</span>
    </button>
    <button
      routerLink="/rechtliches"
      [queryParams]="{id: 'Impressum'}"
      [class.active]="aktuelleUrl == 'Impressum'"
    >
      <mat-icon aria-label="Impressum">gavel</mat-icon>
      <span>Impressum</span>
    </button>
    <button
      routerLink="/rechtliches"
      [queryParams]="{id: 'Kontakt'}"
      [class.active]="aktuelleUrl == 'Kontakt'"
    >
      <mat-icon aria-label="Kontakt">perm_phone_msg</mat-icon>
      <span>Kontakt</span>
    </button>
    <button
      routerLink="/rechtliches"
      [queryParams]="{id: 'Kündigung'}"
      [class.active]="aktuelleUrl == 'Kündigung'"
      *ngIf="mandant.mailerAktiv && kuendigung"
    >
      <mat-icon aria-label="Kündigung">playlist_remove</mat-icon>
      <span>Kündigung</span>
    </button>
    <button
      routerLink="/rechtliches"
      [queryParams]="{id: 'FAQ'}"
      [class.active]="aktuelleUrl == 'FAQ'"
      *ngIf="faq"
    >
      <mat-icon aria-label="FAQ">help</mat-icon>
      <span>FAQ</span>
    </button>
  </div>
  <div id="logo">
    <span>powered by</span>
    <a target="_blank" href="https://www.rcs.de">
      <img src="./assets/icons/company.webp">
    </a>
  </div>
</div>
