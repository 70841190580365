import {Component, inject, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';
import {MandantenService} from '../../../../services/mandanten/mandanten.service';
import {KundeService} from '../../../../services/kunden/kunde.service';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.css']
})
export class SnackbarComponent implements OnInit {

  public mandant
  public kunde

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public mitteilung: any,
    public snackBarRef: MatSnackBarRef<SnackbarComponent>,
    private mandantService: MandantenService,
    private kundeService: KundeService,
  ) {}

  ngOnInit(): void {
    this.kundeService.kunde_get().subscribe((kunde) => {
      this.kunde = kunde;
    })
    this.mandantService.mandant_get().subscribe((mandant) => {
      this.mandant = mandant
    })
  }

}
