import {AfterViewInit, Component, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'kalender-dialog',
  templateUrl: './kalender-dialog.component.html',
  styleUrls: ['./kalender-dialog.component.css']
})
export class KalenderDialogComponent implements AfterViewInit{
  public detectedPlattform: string

  constructor(
    public dialogRef: MatDialogRef<KalenderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    ) {}

  ngAfterViewInit(): void {
    this.detectedPlattform = environment.type
  }
}
