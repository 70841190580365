import { Injectable } from '@angular/core';
import {BehaviorSubject, zip} from 'rxjs';
import { MandantenService } from '../mandanten/mandanten.service';

@Injectable()
export class MenueSyncroSopro {

  constructor(
    private mandant_service: MandantenService,
  ) {
  }

  public modus = new BehaviorSubject<any>(false)
  public config = new BehaviorSubject({
    menus: {},
    bestellungen: {}
  })
  public tagesListe = []
  public soproGrpListe
  public mainGrp
  public alleMenus

  public mengenSyncByGrp(aktuellesMenu, bestellungen, alleMenus) {
    this.mandant_service.mandant_get().subscribe(mandant => {
      this.soproGrpListe = mandant.sonderKonfiguration.Bestellung_mengenSyncByGrp[aktuellesMenu.splanId]
      this.alleMenus = alleMenus
      this.pruefeBestellteTage(bestellungen.datum)
      this.pruefeObMainMenu(mandant, aktuellesMenu, bestellungen)
    })
  }

  public pruefeBestellteTage(datum){
    let vorhanden = this.tagesListe.find(item => item == datum)
    !vorhanden ? this.tagesListe.push(datum) : false
  }

  public pruefeObMainMenu(mandant, aktuellesMenu, bestellungen){
    let vorhanden = Object.values(this.soproGrpListe).some((item:any) => item.mainGrp == aktuellesMenu.gruppe)
    if (vorhanden){
      this.mainGrp = aktuellesMenu.gruppe
      this.pruefeObSchonBestellt(aktuellesMenu, bestellungen, 'main')
    } else {
      this.mainGrp = this.findeMainGrp(aktuellesMenu)
      this.pruefeObSchonBestellt(aktuellesMenu, bestellungen, 'sec')
    }
  }

  public pruefeObSchonBestellt(aktuellesMenu, bestellungen, status){
    let menues:any
    for (menues of Object.values(bestellungen.bestellMenues)){
      if (menues.menueTextId == aktuellesMenu.menuetextid){
        if (menues.mengeAlt != 0){
          if (status == 'main'){
            this.aktiviereSecGrpByNull(bestellungen, menues)
          } else {
            this.pruefeObSecMenu(bestellungen, menues)
          }
        } else {
          if (menues.mengeNeu != menues.mengeAlt && status == 'main'){
            this.aktiviereSecGrpByNull(bestellungen, menues)
          } else {
            this.pruefeObSecMenu(bestellungen, menues)
          }
        }
      }
    }
  }

  public aktiviereSecGrpByNull(bestellungen, aktuellesMenue){
    for (let secGrp of this.soproGrpListe[this.mainGrp].secGrps) {
      const filter = this.alleMenus.filter(item => item.gruppe === secGrp);
      let pruefung = this.pruefeObSecSchonBestellt(filter, bestellungen)
      let neuesMenu:any
      if (pruefung){
        this.secMenuAktualisieren(aktuellesMenue, pruefung)
      } else {
        neuesMenu = Object.values(bestellungen.bestellMenues).filter((item:any) => item.menueTextId == filter[0].menuetextid)
        neuesMenu[0].mengeNeu = aktuellesMenue.mengeNeu
      }
    }
  }

  public pruefeObSecSchonBestellt(filter, bestellungen){
    let index = 0
    for (let menues of filter){
      let menu:any = Object.values(bestellungen.bestellMenues).filter((item:any) => item.menueTextId == menues.menuetextid)
      if (menu[0].mengeNeu != 0 && index != 0){
        return menu
        break
      }
      index++
    }
    return false
  }

  public pruefeObSecMenu(bestellungen, aktuellesMenue){
    for (let secGrp of this.soproGrpListe[this.mainGrp].secGrps) {
      const filterSec = this.alleMenus.filter(item => item.gruppe === secGrp);
      const menuSec:any = Object.values(bestellungen.bestellMenues).filter((item:any) => item.menueTextId == filterSec[0].menuetextid)
      if (menuSec[0].mengeNeu != 0 || menuSec[0].mengeAlt != 0){
        const filterMain = this.alleMenus.filter(item => item.gruppe === this.soproGrpListe[this.mainGrp].mainGrp);
        const menuMain:any = Object.values(bestellungen.bestellMenues).filter((item:any) => item.menueTextId == filterMain[0].menuetextid)
        menuSec[0].mengeNeu = 0
        aktuellesMenue.mengeNeu = menuMain[0].mengeNeu
      }
    }
  }

  public secMenuAktualisieren(mainMenu, secMenu){
    secMenu[0].mengeNeu = mainMenu.mengeNeu
  }

  public findeMainGrp(secMenu){
    let mainGrp:any
    for (mainGrp of Object.values(this.soproGrpListe)){
      for (let secGrps of mainGrp.secGrps){
        if (secGrps == secMenu.gruppe){
          return mainGrp.mainGrp
        }
      }
    }
  }
}
