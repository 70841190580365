import { Component } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-mobil-dialog-update',
  templateUrl: './mobil-dialog-update.component.html',
  styleUrls: ['./mobil-dialog-update.component.css']
})
export class MobilDialogUpdateComponent {

  constructor(private dialogRef: MatDialogRef<MobilDialogUpdateComponent>) { }

  neuladen() {
    this.dialogRef.close({ neuladen: true });
  }

}
