import {Component, Input, OnInit} from '@angular/core';
import {MenuVorauswahlService} from '../../services/menuVorauswahl.service';



@Component({
  selector: 'menuVorauswahlToolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css'],
})

export class ToolbarComponent implements OnInit {

  @Input('mandant') mandant
  @Input('apiZeitraum') apiZeitraum
  @Input('kunde') kunde

  constructor(
    private menuVorauswahlService: MenuVorauswahlService,
  ) {
  }

  ngOnInit() {
  }

  public menuVorauswahlLaden(eventZeitraum){
    this.menuVorauswahlService.menuVorauswahlGet(this.kunde, eventZeitraum)
  }
}
