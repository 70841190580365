import {AfterViewInit, Component } from '@angular/core';
import {ApiCallService} from '../../../../services/api/api-call.service';
import {environment} from '../../../../environments/environment';
import { AppStateService } from 'services/app/app-state.service';
import {ContentBlockService} from '../../../../services/prozesse/contentBlock.service';

@Component({
  selector: 'app-registrierung-zwischenseite',
  templateUrl: './registrierung-zwischenseite.component.html',
  styleUrls: ['./registrierung-zwischenseite.component.css'],
})
export class RegistrierungZwischenseiteComponent implements AfterViewInit {

  public zwischenseiteContent
  public zustand

  constructor(
    private appStateService: AppStateService,
    private contentBlockService: ContentBlockService,
  ) {
    this.appStateService.set_page_name('Neukundenregistrierung');
    this.appStateService.set_seitenname('registrierungsZwischenseite')
  }

  ngAfterViewInit() {
    this.zustand = environment.type
    let zweck = environment.type == 'mobil' ? 'App_RegistrierungZwischenseite' : 'Web_RegistrierungZwischenseite'
    this.contentBlockService.getContentBlock(zweck).subscribe((result:any) => {
      this.zwischenseiteContent = result
    })
  }
}
