import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {environment} from '../../../../../environments/environment';
import {ExpressStornoDialogComponent} from '../expressStorno-dialog/expressStorno-dialog.component';
import {NavigationEnd, Router} from '@angular/router';

@Component({
    selector: 'expressStorno-icon',
    templateUrl: './expressStorno-icon.component.html',
    styleUrls: ['./expressStorno-icon.component.css'],
})
export class ExpressStornoIconComponent implements OnInit {

  @Input('kunde') kunde
  @Input('mandant') mandant
  public verfuegbar = false
  public mobil = false

  constructor(
    private matDialog: MatDialog,
    private router: Router,
  ) {}

  ngOnInit(){
    this.verfuegbar = this.router.url != '/warenkorb' ? true : false;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd){
        this.verfuegbar = event.url != '/warenkorb' ? true : false;
      }
    })
    this.mobil = environment.type != 'web' ? true : false
  }

  dialogOpen(){
    this.matDialog.open(ExpressStornoDialogComponent, {
      data: {
        kunde: this.kunde,
        mandant: this.mandant
      },
    })
  }

  protected readonly environment = environment;
}
