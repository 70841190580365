import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { KundeService } from 'services/kunden/kunde.service';
import { MitteilungsService } from 'services/prozesse/mitteilung.service';
import { MatDialog } from '@angular/material/dialog';
import {Platform} from '@angular/cdk/platform';

@Component({
    selector: 'passwort-vergessen',
    templateUrl: './passwort-vergessen.component.html',
    styleUrls: ['./passwort-vergessen.component.css'],
})
export class PasswortVergessenComponent implements OnInit {

    public passwort_vergessen = new UntypedFormGroup({
      kundennummer: new UntypedFormControl('', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])
    })
    public error = false
    public send:boolean = false
    public mitteilung: object | boolean = false
    public gesendet = true
    public detectedPlattform: string


    constructor(
        private kunde_service: KundeService,
        private router: Router,
        private mitteilung_service: MitteilungsService,
        public dialog: MatDialog,
        public platform: Platform
        ) {}

    ngOnInit() {
      if (this.platform.ANDROID) {
        this.detectedPlattform = 'ANDROID'
      }
      else if (this.platform.IOS) {
        this.detectedPlattform = 'IOS'
      }
      else {
        this.detectedPlattform = 'WEB'
      }
    }

    on_submit(){
      this.kunde_service.kunde_passwort_vergessen(this.passwort_vergessen.value.kundennummer).subscribe(result => {
         this.gesendet = false
          if (this.detectedPlattform == 'WEB'){
            this.mitteilung_service.setMitteilung("1013")
            this.dialog.closeAll()
          }
          else {
            this.mitteilung_service.set_snackbar("1013", 5000)
          }
        },
          error => {
            if (this.detectedPlattform == 'WEB'){
              this.mitteilung_service.setMitteilung(JSON.stringify(error.error.content.msgId))
            }
            else {
              this.mitteilung_service.set_snackbar(JSON.stringify(error.error.content.msgId), 5000)
            }
          },
          () => {
            this.passwort_vergessen.controls.kundennummer.setValue('');
            this.send = true
      })
    }

    navigation_login(){
      if (this.detectedPlattform != 'WEB'){
        this.router.navigateByUrl('/login')
      }
  }
}
