import {Component, OnInit, AfterViewInit, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'fehlerDialog',
  templateUrl:  './fehlerDialog.component.html',
  styleUrls: ['./fehlerDialog.component.css'],
})

export class FehlerDialogComponent implements OnInit, AfterViewInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any
  ) {
    console.log(this.data)
  }

  ngOnInit() {}

  ngAfterViewInit() {}
}
