<div id="speiseplan-menu" [class.web]="detectedPlattform == 'web'" [class.zusatzGrid]="menuZusatz && detectedPlattform == 'web'" [class.zusatzGridWba]="menuZusatz && detectedPlattform != 'web'">
  <div class="speiseplan-menu-titel" [class.keinPreis]="!menu.preis || mandant.preisPositionInSplan == 'none'">
    <strong>{{ menu.bezeichnung }}</strong>
    <strong *ngIf="menu.preis && mandant.preisPositionInSplan !== 'none' && preisAnzeige">{{ menu.preis | speiseplanpreis }}</strong>
  </div>

  <div class="tooltip" *ngIf="menu.menueText !== ''" [class.zusatz]="menuZusatz">
    <div
      id="speiseplanMenuBeschreibung"
      [innerHTML]="menu.menueText | bbCode"
      [class.web]="detectedPlattform == 'web'"
      [class.zusatz]="menuZusatz"
    >
    </div>
    <span class="tooltiptext" *ngIf="environment.type !== 'mobil'" [innerHTML]="menu.menueText | bbCode"></span>
  </div>

  <div id="speiseplanAIZ">
    <ng-container *ngIf="menuAIZ">
      <sup *ngIf="menuAIZ.length != 0" [innerHTML]="menuAIZ"></sup>
    </ng-container>
    <ng-container *ngIf="menuZusatz">
      <sup [innerHTML]="menuZusatz | img | async"></sup>
    </ng-container>
  </div>

  <div id="menuSymbole">
    <img *ngIf="menu.symbol" class="menu-icon" [attr.src]="(menu.symbol | symbol) | async" />
    <mat-icon>more_horiz</mat-icon>
  </div>
</div>
