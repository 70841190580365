<div class="einstellungen">
  <div id="matDialogContentHeader" *ngIf="detectedPlattform == 'WEB'">
    <strong>Einstellungen</strong>
    <mat-icon mat-dialog-close >close</mat-icon>
  </div>
    <form class="einstellung-content" [formGroup]="form">
        <div class="sche">
<!-- Todo: überlegen ob es sinniger wäre statt i btn einen ausklappbaren text zu machen -->
            <div class="einstellung" *ngFor="let gruppen of einstellungen">
                <div (click)="information(gruppen[4].text)" *ngIf="gruppen[0].active" class="infoSpan" (click)="gruppen[1].onclick">
                    <mat-icon>{{gruppen[1].icon}}</mat-icon>
                    <span>{{gruppen[1].span}}</span>
                </div>
                <div class="modusSlide">
                    <span *ngIf="gruppen[2].span">{{this[gruppen[2].span] |tagwoche}}</span>
                    <mat-slide-toggle [checked]='this[gruppen[3].checked]' class="mat-toggle" id={{gruppen[3].id}}
                        (change)='sliderChange(gruppen[3].changeParameter)'
                        formControlName={{gruppen[3].formControlName}}>
                    </mat-slide-toggle>
                </div>
            </div>
        </div>

        <mat-card class="buttons">
          <button (click)="werkseinstellungen()" class="löschen">Zurücksetzen</button>
          <button [disabled]="!changed" (click)="speichern()" class="speichern"> Speichern </button>
        </mat-card>
    </form>
</div>
