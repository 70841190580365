import { Component, OnInit } from '@angular/core';



@Component({
  selector: 'menuVorauswahlBeschreibung',
  templateUrl: './beschreibung.component.html',
  styleUrls: ['./beschreibung.component.css'],
})

export class BeschreibungComponent implements OnInit {

  constructor(
  ) {
  }

  ngOnInit() {
  }
}
