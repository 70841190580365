import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BbCodePipe} from './bb-code.pipe';
import {DateConvertPipe} from './date-convert.pipe';
import {DateWeekdayPipe} from './date-weekday.pipe';
import { SymbolPipe } from './symbol.pipe';
import { Base64Pipe } from './base64.pipe';
import { BestellzeitenPipe } from './bestellzeiten.pipe';
import { DatumPipe } from './datum.pipe';
import { BestellSchlussIconPipe } from './bestellschlussicon.pipe';
import { FormPipe } from './form.pipe';
import { MenuPipe } from './menu.pipe';
import { SpeiseplanTagesmenuPipe } from './speiseplan-tagesmenu.pipe';
import { SpeiseplanPreisPipe } from './speiseplan-preis.pipe';
import { PflichtPipe } from './pflicht.pipe';
import { EuroPricePipe } from './euro-price.pipe';
import { TagWochePipe } from './tag-woche.pipe';
import { SpeiseplanAufklappenPipe } from './speiseplan-aufklappen.pipe';
import { ZustellPipe } from './zustellpause.pipe';
import { FeierPipe } from './feierpipe.pipe';
import { GuthabenPipe } from './guthaben.pipe';
import { AizTextPipe } from './aiz-text.pipe';
import { PasswortPipe } from './passwort.pipe';
import { HauptmenuTextPipe } from './hauptmenu-text.pipe';
import { BooleanPipe } from './boolean.pipe copy';
import { PseudonymPipe } from './pseudonym.pipe';
import {MitteilungPipe} from './mitteilung.pipe';
import { ImgPipe } from './img.pipe';
import { KeineAngabenPipe } from './keine-angaben.pipe';
import { GesetzestextePipe } from './gesetzestexte.pipe';
import {TrustHTMLPipe} from './trustHTML.pipe';
import {UnsafeHTMLImgPipe} from './unsafeHTMLImg.pipe';
import {ErrorPipe} from './error.pipe';
import {LeerzeichenPipe} from './leerzeichen.pipe';
import {KalenderDatumPipe} from './kalender-datum.pipe';
import {MonatPipe} from './monat.pipe';
import {VideoPipe} from './video.pipe';
import {BestellhistoriePreisPipe} from './bestellhistoriePreis.pipe';
import {SpeiseplanMenuSelectorPipe} from './speiseplan-menuSelector.pipe';
import {SpeiseplanGetTagesmenuPipe} from './speiseplan-getTagesmenu.pipe';
import {MehrwegEinwegPipe} from './mehrwegEinweg.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    BbCodePipe,
    DateConvertPipe,
    DateWeekdayPipe,
    SymbolPipe,
    Base64Pipe,
    BestellzeitenPipe,
    BestellSchlussIconPipe,
    SpeiseplanTagesmenuPipe,
    SpeiseplanAufklappenPipe,
    HauptmenuTextPipe,
    DatumPipe,
    FormPipe,
    MenuPipe,
    SpeiseplanPreisPipe,
    EuroPricePipe,
    TagWochePipe,
    ZustellPipe,
    FeierPipe,
    GuthabenPipe,
		AizTextPipe,
		PflichtPipe,
		PasswortPipe,
    BooleanPipe,
    PseudonymPipe,
    MitteilungPipe,
    ImgPipe,
    KeineAngabenPipe,
    GesetzestextePipe,
    TrustHTMLPipe,
    UnsafeHTMLImgPipe,
    ErrorPipe,
    LeerzeichenPipe,
    KalenderDatumPipe,
    MonatPipe,
    KalenderDatumPipe,
    VideoPipe,
    BestellhistoriePreisPipe,
    SpeiseplanMenuSelectorPipe,
    SpeiseplanGetTagesmenuPipe,
    MehrwegEinwegPipe,
  ],
    exports: [
        BbCodePipe,
        DateConvertPipe,
        DateWeekdayPipe,
        SymbolPipe,
        Base64Pipe,
        BestellzeitenPipe,
        BestellSchlussIconPipe,
        SpeiseplanTagesmenuPipe,
        SpeiseplanAufklappenPipe,
        HauptmenuTextPipe,
        DatumPipe,
        FormPipe,
        MenuPipe,
        SpeiseplanPreisPipe,
        EuroPricePipe,
        TagWochePipe,
        ZustellPipe,
        FeierPipe,
        GuthabenPipe,
        AizTextPipe,
        PflichtPipe,
        PasswortPipe,
        BooleanPipe,
        PseudonymPipe,
        MitteilungPipe,
        ImgPipe,
        KeineAngabenPipe,
        GesetzestextePipe,
        TrustHTMLPipe,
      UnsafeHTMLImgPipe,
      ErrorPipe,
      LeerzeichenPipe,
      KalenderDatumPipe,
      MonatPipe,
      KalenderDatumPipe,
      VideoPipe,
      BestellhistoriePreisPipe,
      SpeiseplanMenuSelectorPipe,
      SpeiseplanGetTagesmenuPipe,
      MehrwegEinwegPipe,
    ]
})
export class PipeModule { }
