<div id="matDialogContent">
  <div id="matDialogContentHeader">
    <strong>Achtung</strong>
    <mat-icon mat-dialog-close >close</mat-icon>
  </div>
  <div id="matDialogContentData" [innerHTML]="message"></div>
  <div id="matDialogContentFooter">
    <button class="option-no" (click)="onDismiss()">Nein</button>
    <button class="option-yes" (click)="onConfirm()">Ja</button>
  </div>
</div>
