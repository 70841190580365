<div id="dialogUpdate">
  <strong>Softwareaktualisierung</strong>
  <div id="dialogUpdateContent">
    <span>
      Eine neue Version der Web-Bestell-App ist verfügbar.
      Die Anwendung wird geschlossen um das Update zu installieren.
      Kann die Web-Bestell-App jetzt aktualisiert werden?
    </span>
  </div>
  <div mat-dialog-actions id="matDialogAktionen">
    <button mat-flat-button mat-dialog-close>Später</button>
    <button mat-flat-button mat-dialog-close cdkFocusInitial (click)="neuladen()">Aktualisieren</button>
  </div>
</div>
