import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {MenuVorauswahlService} from '../../services/menuVorauswahl.service';

@Component({
  selector: 'menuVorauswahlEditor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.css'],
})

export class MenuVorauswahlEditorComponent implements OnInit {

  public menu
  public aktuelleMenuVorauswahl = {}
  public buffer
  public id

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data,
    private menuVorauswahlService: MenuVorauswahlService,
  ) {
  }

  ngOnInit() {
    this.aktuelleMenuVorauswahl = this.menuVorauswahlService.menuVorauswahl.getValue()
    this.buffer = { ...this.aktuelleMenuVorauswahl[this.data.tag.datum].vorausgewaehlt}
    this.menu = this.data.menu
  }

  public aktualisiereVorauswahl(event, menuNr, menu){
    if (!this.buffer.menues[menuNr]){
      this.buffer.menues[menuNr] = {}
    }
    if (event.value !== 'false'){
      this.buffer.menues[menuNr][menu.pos] = menu.menueIds.filter(item => item == event.value[0])[0]
    }else{
      delete this.buffer.menues[menuNr][menu.pos]
    }
  }

  public speichereVorauswahl(){
    this.aktuelleMenuVorauswahl[this.data.tag.datum].vorausgewaehlt = this.buffer
    this.menuVorauswahlService.menuVorauswahl.next(this.aktuelleMenuVorauswahl)
    let hauptmenuIndex = this.data.tag.vorausgewaehlt.menues[this.menu.menueNr]['1']
    if (hauptmenuIndex){
      let menuInformationen = this.data.tag.tagesMenues[hauptmenuIndex.replace('M', '')]
      this.menuVorauswahlService.updateMenuVorauswahlSeitenleiste(this.data.mandant, menuInformationen, this.buffer, this.menu, hauptmenuIndex, this.data.tag)
    }
    this.dialog.closeAll()
  }

  /** TODO Überbrückung für MMS -> Rausnehmen wenn MMS das übernimmt **/
  public hauptkomponenteBereitsGewaehlt(vorauswahl, menueId, position, menuNr){
    if (position == 1){
      for (let hauptkomponente in vorauswahl.menues){
        if (hauptkomponente != menuNr){
          if (vorauswahl.menues[hauptkomponente][position] == menueId){
            return true
          }
        }
      }
    }
    return false
  }

  public verwerfeVorauswahl(){
    this.dialog.closeAll()
  }

  public setzeVorauswahlzurueck(nr){
    delete this.buffer.menues[nr]
  }

  public zuruecksetzen(nr, pos){
    delete this.buffer.menues[nr][pos]
  }

  public loeschenMoeglich(nr, pos){
    if (this.buffer.menues){
      if (this.buffer.menues[nr]){
        if (this.buffer.menues[nr][pos]){
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    } else {
      return false
    }

  }
}
