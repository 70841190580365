<div *ngIf="dataSource" id="faqContainer">
  <div class="suche">
    <mat-icon>search</mat-icon>
    <input
      type="text"
      placeholder="Suchen..."
      id="inputFilter"
      [(ngModel)]="searchText"
    />
    <mat-icon *ngIf="searchText != ''" (click)="clearFilter(); accordion.closeAll()">close</mat-icon>
  </div>
  <div [class.tableWba]="detectedPlattform != 'WEB'">
    <mat-accordion multi *ngFor="let element of filteredItems()">
      <mat-expansion-panel mat-cell [class.panelWba]="detectedPlattform != 'WEB'" [expanded]="openPanel(element.name) && direktOffen" [id]="getPanelId(i)" class="faqKacheln">
        <mat-expansion-panel-header>
          <mat-panel-title [innerHTML]="'<strong>' + element.name + '</strong>'" class="faqTitel">
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p *ngIf="element.name != 'Installationsanleitung'" class="contentblock"
           [innerHTML]="element.content | bbCode | img | trustHTML | async"></p>
        <div *ngIf="element.name == 'Installationsanleitung'">
          <app-pwa-install></app-pwa-install>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
