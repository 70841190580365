import { AfterViewInit, Component, OnInit } from '@angular/core';
import {IBestellzeitraum} from '../../../../services/interface/bestellung.interface';
import {SeitenleisteService} from 'submodul/obs.seitenleiste/seitenleiste.service';
import {MandantenService} from '../../../../services/mandanten/mandanten.service';
import {ContentBlockService} from '../../../../services/prozesse/contentBlock.service';

@Component({
	selector: 'warenkorb-seitenleiste',
	templateUrl: './warenkorb.seitenleiste.component.html',
	styleUrls: ['./warenkorb.seitenleiste.component.css'],
})
export class WarenkorbSeitenleisteComponent implements OnInit, AfterViewInit {

  public bestellungen: IBestellzeitraum
  public bestellungen_meta
  public preis_anzeige
  public warenkorb_titel
  public mandant

  constructor(
    private seitenleistenService: SeitenleisteService,
    private mandantenService: MandantenService,
    private contentBlockService: ContentBlockService,
	) { }

	ngOnInit() {
    this.mandantenService.mandant_get().subscribe(mandant => this.mandant = mandant)
	}

  ngAfterViewInit() {
    this.warenkorb_titel_abrufen()
  }

  confirmDialog(){
    this.seitenleistenService.emitterSeitenleistenInhalt('confirmDialog')
  }

  bestellung_commit(){
    this.seitenleistenService.emitterSeitenleistenInhalt('bestellung_commit')
  }

  public warenkorb_titel_abrufen(){
    this.contentBlockService.getContentBlock('Web_WarenkorbTitel').subscribe((result:any) => {
      if (result.length != 0){
        this.warenkorb_titel = atob(result[0].content)
      } else {
        this.warenkorb_titel = 'Auf Grundlage des Verköstigungsvertrages:'
      }
    })
  }
}
