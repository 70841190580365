import { NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectiveModule } from '../../directives/directives.module'
import { PipeModule } from '../../pipe/pipe.module';
import {MaterialModule} from '../../application/web/material.module';
import {TagComponent} from './components/tag/tag.component';
import {MenuComponent} from './components/menu/menu.component';
import {BeschreibungComponent} from './components/beschreibung/beschreibung.component';
import {AizComponent} from './components/aiz/aiz.component';
import {AuswahlComponent} from './components/auswahl/auswahl.component';
import {MenuVorauswahlComponent} from './menuVorauswahl.component';
import {MenuVorauswahlEditorComponent} from './components/editor/editor.component';
import {SpeiseplanModule} from '../obs.speiseplan/speiseplan.module';
import {MenuVorauswahlMenukarteComponent} from './components/menuVorauswahlMenukarte/menuVorauswahlMenukarte.component';
import {ToolbarComponent} from './components/toolbar/toolbar.component';
import {MenuToolsComponent} from './components/menuTools/menuTools.component';
import {MenuVorauswahlMenunamePipe} from './services/menuVorauswahlMenuname.pipe';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  imports: [
    MatIconModule,
    BrowserModule,
    CommonModule,
    RouterModule,
    FormsModule,
    PipeModule,
    DirectiveModule,
    ReactiveFormsModule,
    MaterialModule,
    SpeiseplanModule,
    MatChipsModule,
  ],
  declarations: [
    MenuVorauswahlComponent,
    MenuVorauswahlEditorComponent,
    TagComponent,
    MenuComponent,
    BeschreibungComponent,
    AizComponent,
    AuswahlComponent,
    MenuVorauswahlMenukarteComponent,
    ToolbarComponent,
    MenuToolsComponent,
    MenuVorauswahlMenunamePipe,
  ],
  exports: [
    MatIconModule,
    MenuVorauswahlComponent,
    MenuVorauswahlEditorComponent,
    TagComponent,
    MenuComponent,
    BeschreibungComponent,
    AizComponent,
    AuswahlComponent,
    ToolbarComponent,
    MenuToolsComponent,
  ],
})
export class MenuVorauswahlModule { }
