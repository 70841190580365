import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-verify-dialog',
  templateUrl:  './verify-dialog.components.html',
  styleUrls: ['./verify-dialog.components.css']
})
export class VerifyDialogComponent implements OnInit {
  modus: string;

  constructor(
    public dialogRef: MatDialogRef<VerifyDialogComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data:any)

  {
    dialogRef.disableClose = true
  }

  ngOnInit() {
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.router.navigateByUrl('/login')
    this.dialogRef.close(true);
  }
}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class ConfirmDialogModel {

  constructor(public title: string, public message: string) {
  }
}
