import {Injectable} from '@angular/core';
import {environment} from 'environments/environment';
import {map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {WerbePopupComponent} from '../werbePopup/werbePopup.component';
import {KundeService} from '../../../../services/kunden/kunde.service';
import {ContentBlockService} from '../../../../services/prozesse/contentBlock.service';

@Injectable()
export class WerbebannerService {

  public werbebannerModus:string
  public ankuendigungsBannerContent
  public werbePopupContent

  constructor(
    private contentBlockService: ContentBlockService,
    private kundeService: KundeService,
    private matDialog: MatDialog
  ) {
    this.werbebannerModus = environment.type == 'web' ? 'Web' : 'App'
  }

  public ankuendigungsContentPopup(){
    return this.contentBlockService.getContentBlock(`${this.werbebannerModus}_Werbebanner`).pipe(
      switchMap((apiResponse) => {
        if (apiResponse && apiResponse[0]){
          this.ankuendigungsBannerContent = apiResponse
          return this.contentBlockService.getContentBlock(`${this.werbebannerModus}_Werbepopup`)
        }else{
          return of(false)
        }
      }),
      map((apiAnkuendigungsPopupResponse) => {
        if (apiAnkuendigungsPopupResponse && apiAnkuendigungsPopupResponse[0]){
          this.werbePopupContent = apiAnkuendigungsPopupResponse
          this.ersteMalWerbepopupTriggern()
          return true
        }else{
          return false
        }
      })
    )
  }

  public ersteMalWerbepopupTriggern(){
    this.kundeService._kunde_get().subscribe(kunde => {
      if (!localStorage.getItem(`Werbepopup_${kunde.kundeId}`)){
        localStorage.setItem(`Werbepopup_${kunde.kundeId}`, 'true')
        this.ankuendigungsPopup()
      }
    })
  }

  public ankuendigungsPopup() {
    const dialogRef = this.matDialog.open(WerbePopupComponent, {
      data: {werbePopupContent: this.werbePopupContent},
      panelClass: 'werbePopup'
    })
    dialogRef.afterClosed().subscribe(result => {})
  }
}

