<div id="menuVorauswahlSeitenleistenContainer">
  <div id="menuVorauswahlSeitenleistenOptionen">
    <button (click)="speichereVorauswahl()" >Änderungen übernehmen</button>
    <button>Änderungen verwerfen</button>
  </div>
  <div id="menuVorauswahlSeitenleistenInhalt">
    <div id="speiseplanDetail" *ngIf="menu && mandant">
      <div class="speiseplan-details-titel">
        <strong>{{ menu.bezeichnung }}</strong>
        <strong *ngIf="mandant.preisPositionInSplan !== 'none'">{{ menu.preis | euroPrice }}</strong>
      </div>
      <div id="speiseplanDetailsContent">
        <div class="speiseplan-details-beschreibung details-abschnitt" [innerHTML]="menu.menueText | bbCode"></div>
        <div class="speiseplan-details-beschreibung details-abschnitt" *ngFor="let test of Object.values(neben)" [innerHTML]="test.menueText | bbCode"></div>
        <div *ngIf="menu.naehrwerte.length > 0" class="speiseplan-details-naehrwert details-abschnitt">
          <strong>Nährwerte</strong>
          <div class="speiseplan-details-naehrwert-tabelle">
            <div *ngIf="menu.portionsGroesse" id="speiseplanDetailsPortion">
              <span>Portionsgröße:</span>
              <span>{{ menu.portionsGroesse }}g</span>
            </div>
            <span>Nährwerte pro 100g</span>
            <div *ngFor="let naehrwert of menu.naehrwerte">
              <span>{{ naehrwert.name }}</span>
              <span>{{ naehrwert.wert }}</span>
            </div>
          </div>
        </div>
      </div>
      <div id="seitenleisteGlossar">
        <speiseplan-glossar [mandant]="mandant"></speiseplan-glossar>
      </div>
    </div>
  </div>
</div>
