<div id="vorauswahlMenuContainer" (click)="aktivesMenue($event)">
  <div
    *ngIf="!tag.vorausgewaehlt.menues || !(tag.vorausgewaehlt.menues[menu.menueNr] && tag.vorausgewaehlt.menues[menu.menueNr]['1'])"
    id="menuKarteLeer"
    (click)="menuVorauswahlEditor()"
    [class.gesperrt]="gesperrt"
  >
    <mat-icon>add</mat-icon>
  </div>
  <div *ngIf="tag.vorausgewaehlt.menues && (tag.vorausgewaehlt.menues[menu.menueNr] && tag.vorausgewaehlt.menues[menu.menueNr]['1'])" id="menuKarteVoll">
    <menuVorauswahlMenukarte
      id="menuKarte"
      [tag]="tag"
      [menu]="menu"
      [mandant]="mandant"
    ></menuVorauswahlMenukarte>
    <menuVorauswahlMenuTools [tag]="tag" [menu]="menu" [mandant]="mandant"></menuVorauswahlMenuTools>
  </div>
</div>
