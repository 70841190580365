<div
  *ngFor="let menu of tag.value.tagesMenues | speiseplanTagesmenus:speiseplan.menutexte; let last = last; let i = index"
  class="speiseplanMenu"
  [class.darstellung]="darstellung != 1 && os == 'web'"
  id="menu{{menu.menueNr}}"
>

  <speiseplanMenu
    *ngIf="!verpackungsId || verpackungsId !== speiseplanId ; else menuVerpackung"
    id="splanMenu"
    [class.speiseplanMenuWba]="os != 'web'"
    [mandant]="mandant"
    [kunde]="kunde"
    [menu]="menu"
    [speiseplanMenuDatum]="tag.key"
    [speiseplan]="speiseplan"
    [config]="config"
  ></speiseplanMenu>

  <ng-template #menuVerpackung>
    <speiseplanMenuVerpackung
      [kunde]="kunde"
      [menu]="menu"
      [mandant]="mandant"
      [config]="config"
      [tag]="tag"
      [speiseplan]="speiseplan"
      [speiseplanMenuDatum]="tag.key"
    >
    </speiseplanMenuVerpackung>
  </ng-template>
</div>
