import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'speiseplanMenuSelector'
})
export class SpeiseplanMenuSelectorPipe implements PipeTransform {

  transform(value: number, data: any): number | null {
    const foundItem = data[0][value - 1];
    if (foundItem) {
      return foundItem.Einweg;
    }
    return null;
  }

}
