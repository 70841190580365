import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mehrwegEinweg'
})
export class MehrwegEinwegPipe implements PipeTransform {

  transform(text: string, status: string): string {
    if (text){
      if (text == 'Mehrweg'){
        return status == 'label' ? 'Mehrweg' : './assets/icons/mehrwegIcon.webp'
      } else {
        return status == 'label' ? 'Einweg' : './assets/icons/einwegIcon.webp'
      }
    }
    return text
  }

}
