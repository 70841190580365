import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from 'components/mobil/confirm-dialog/confirm-dialog.components';
import { BestellungService } from '../bestellung/bestellung.service';
import { KundeService } from '../kunden/kunde.service'

declare var device
declare var navigator

@Injectable()
export class CordovaService {

	public cordova = window['cordova']
	public validat

	constructor(
		private bestellungen_service: BestellungService,
		private router: Router,
		private dialog: MatDialog,
		private kunde_service: KundeService,
	) {

		const _kunde_service = kunde_service
		const _bestellungen_service = bestellungen_service
		const _dialog = dialog
		const _router = router

		document.addEventListener("deviceready", function () {
			var exitApp = false, intval = setInterval(function () { exitApp = false; }, 1000);
			document.addEventListener("backbutton", function (e) {
				e.preventDefault();
				if (exitApp) {
					clearInterval(intval)
					navigator.app.exitApp()
				}
				else {
					exitApp = true
					navigator.app.backHistory()
				}
			}, false);
			document.addEventListener("resume", function () {
				setTimeout(function () {
					_kunde_service._kunde_get().subscribe((kunde) => {
						_bestellungen_service.bestellung_validate(kunde).subscribe((id: any) => {
							alert(JSON.stringify(id.error))
							if (id.error === false) {
								_dialog.open(ConfirmDialogComponent, {
									data: {
										title: "Warenkorb",
										message: "Sie haben noch unbearbeitete Bestellungen in Ihrem Warenkorb. \n Möchten Sie diese löschen?",
									}
								}).afterClosed().subscribe((result) => {
									if (result) {
										_bestellungen_service.bestellungen_leeren()
									}
									else {
										_router.navigate(['warenkorb'])
									}
								})
							}
							else {
								_bestellungen_service.bestellungen_leeren()
								if (window.location.href.indexOf('speiseplan') != -1) {
									_router.navigate(['speiseplan-kunde']);
								}
								else {
									window.location.reload()
								}
							}
						});
					})

				}, 0);
			}, false);
		}, false);
	}
}
