import { Component, Input } from '@angular/core';
import {WebDialogComponent} from '../web-dialog/web-dialog.component';
import {WebPdfComponent} from '../web-pdf/web-pdf.component';
import {MatDialog} from '@angular/material/dialog';
import {DateiService} from '../../../services/prozesse/datei.service';

@Component({
  selector: 'web-speiseplan-header',
  templateUrl: './web-speiseplan-header.component.html',
  styleUrls: ['./web-speiseplan-header.component.css'],
})

export class WebSpeiseplanHeaderComponent {
  @Input('kunde') public kunde
  @Input('mandant') public mandant
  @Input('zeitraum') public zeitraum
  @Input('modus') public modus
  @Input('speiseplan') public speiseplan
  @Input('einrichtungsinfo') public einrichtungsinfo
  @Input('pdf_plaene') public pdf_plaene

  constructor(
    public dateiService: DateiService,
    public dialog: MatDialog,
  ) { }

  public openDialog(titel, text) {
    const dialogRef = this.dialog.open(WebDialogComponent, {
      data: {
        title: titel,
        data: text,
        panelClass: 'dialog-container-bestellschluss',
      }
    })
  }

  direktPDF(pdf){
    this.dateiService.speiseplanPDF(Object.values(pdf)[0]).subscribe(file => {
      setTimeout(() => { window.open(URL.createObjectURL(file)) }, 50)
    })
  }

  openPDF() {
    if (Object.keys(this.pdf_plaene).length == 1){
      this.direktPDF(this.pdf_plaene)
    } else {
      const dialogRef = this.dialog.open(WebPdfComponent, {
        data: {
          title: "Verfügbare PDF-Speisepläne",
          data: this.pdf_plaene,
          mandant: this.mandant
        }
      })
    }
  }
}
