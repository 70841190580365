import {Component, Inject, OnInit} from '@angular/core';
import {ApiCallService} from '../../../../../services/api/api-call.service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-rechnungenAnfordern',
  templateUrl: './rechnungenAnfordern.component.html',
  styleUrls: ['./rechnungenAnfordern.component.css']
})
export class RechnungenAnfordernComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<RechnungenAnfordernComponent>,
    private apiCall: ApiCallService,
  ) {}

  ngOnInit() {
    console.log(this.data.mandant)
  }

  public rechnungenAnfordern() {
    this.apiCall.request('/call', 'rechnungsforderung/post', {
      mandantId: environment.mandant_id,
      rechnungsnummer: this.data.rechnungsNr,
    }).subscribe(
      (next) => { this.dialogRef.close(next); },
      (error) => {}
    )
  }

  protected readonly environment = environment;
}
