import {Component, Input, OnInit} from '@angular/core';
import {MenuVorauswahlService} from '../../services/menuVorauswahl.service';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent} from '../../../obs.shared/dialog/confirm-dialog/confirm-dialog.components';

@Component({
  selector: 'menuVorauswahlMenuTools',
  templateUrl: './menuTools.component.html',
  styleUrls: ['./menuTools.component.css'],
})

export class MenuToolsComponent implements OnInit {

  @Input('mandant') mandant
  @Input('menu') menu
  @Input('tag') tag
  public aktuelleMenuVorauswahl:any
  public gesperrt: boolean = false
  constructor(
    private menuVorauswahlService: MenuVorauswahlService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    if (Math.floor(new Date(this.tag.datum).getTime() / 1000) < Math.floor(new Date().setHours(0,0,0,0) / 1000)){
      this.gesperrt = true
    }
  }

  public menuVorauswahlEditor(){
    this.menuVorauswahlService.menuVorauswahlEditor(this.menu, this.tag, this.mandant)
  }

  public menuVorauswahlLoeschen(){
    this.dialog.open(ConfirmDialogComponent, {
      data: {message: 'Sind Sie sicher, dass Sie das erstellte Menü löschen möchten?'},
    }).afterClosed().subscribe((result => {
      if (result){
        this.aktuelleMenuVorauswahl = this.menuVorauswahlService.menuVorauswahl.getValue()
        this.aktuelleMenuVorauswahl[this.tag.datum].vorausgewaehlt.menues[this.menu.menueNr] = {}
        this.menuVorauswahlService.menuVorauswahl.next(this.aktuelleMenuVorauswahl)
        this.menuVorauswahlService.updateMenuVorauswahlSeitenleiste(this.mandant)
      }
    }))
  }
}
