import {Component, Output, ViewChild, OnInit, AfterViewInit, Input} from '@angular/core';
import { EventEmitter } from '@angular/core';
import {SpeiseplanService} from 'submodul/obs.speiseplan/services/speiseplan.service';
import {DbReaderService} from 'services/db-reader/db-reader.service';
import {MatDialog} from '@angular/material/dialog';
import {SpeiseplanKalenderService} from './speiseplan-kalender.service';
import {environment} from '../../../../environments/environment';
import {KalenderPickerComponent} from '../../../obs.shared/fragmente/kalender-picker/kalender-picker.component';

@Component({
	selector: 'speiseplan-kalender',
	templateUrl: './speiseplan-kalender.component.html',
	styleUrls: ['./speiseplan-kalender.component.css'],
})
export class SpeiseplanKalenderComponent implements OnInit, AfterViewInit {

  @Input('mandant') mandant
  @Input('kunde') kunde
  @Input('apiZeitraum') api_zeitraum
	@Output() zeitraum_update = new EventEmitter()
	@ViewChild(KalenderPickerComponent) picker: KalenderPickerComponent
	public zeitraum: any
	public path
	public aktuelles_datum
	public zeitraum_nav = {
		vor: undefined,
		zurueck: undefined
	}
  public object: any
  public bestellschluss
  public disabeled: any = []
  public modus
  public dP: string

	constructor(
    private db: DbReaderService,
    public dialog: MatDialog,
    public speiseplan_service: SpeiseplanService,
    private kalender_service: SpeiseplanKalenderService
	) { }

	ngOnInit() {
    this.dP = environment.type
    this.kalender_service.kalenderTrigger.subscribe(parameter => {
      this.kalenderFunktion(parameter[0], parameter[1]);
    });

    this.db.get('bestellschluss', 'Cache').subscribe((value) => {
      this.bestellschluss = value
    })
    if (this.kunde){
      this.object = {
        zustellpause: Object.keys(this.kunde.zustellPausen).length > 0 ? this.kunde.zustellPausen : null,
        feiertage: this.mandant.feiertage,
      }
      this.kalender_service.object = this.object
    }
	}

  ngAfterViewInit() {
    this.speiseplan_service.get_modus().subscribe((modus:any) => {
      if (modus){
        this.db.get('tagWoche', 'Options').subscribe((value) => {
          if (environment.type != 'web'){
            if (value === true) {
              modus = 'tag'
            } else {
              modus = 'woche'
            }
          }
          this.modus = modus
          this.kalender_service.modus = modus
          this.kalender_service.api_zeitraum = this.api_zeitraum
          this.kalender_service.zeitraumLaden()
        })
      }
    })
  }

  kalenderFunktion(command, parameter){
    this[command](parameter)
  }

  splanService(command, parameter){
    this.kalender_service[command](parameter)
  }

  public navigation(_index){
    let index = parseInt(_index)
    this.zeitraum_nav.vor = this.zeitraum[index + 1] ? index + 1 : undefined
    this.zeitraum_nav.zurueck = this.zeitraum[index - 1] ? index - 1 : undefined
    this.aktuelles_datum = this.zeitraum[index]
    this.zeitraum_update.emit(this.aktuelles_datum)
    if (this.picker){
      this.picker.dialogSchliessenChevron()
    }
  }

  public aktualisieren(datum) {
    let index
    if (datum[2]){
      index = parseInt(datum[2])
    } else {
      index = datum[1].findIndex(element => (element.index == datum[0].index))
      if (index == -1){
        index = datum[1].length - 1
      }
    }
    this.zeitraum_nav.vor = datum[1][index + 1] ? index + 1 : undefined
    this.zeitraum_nav.zurueck = datum[1][index - 1] ? index - 1 : undefined
    this.zeitraum = datum[1]
    this.aktuelles_datum = datum[0]
    this.kalender_service.aktuelles_datum = this.aktuelles_datum
    this.zeitraum_update.emit(this.aktuelles_datum)
  }

  menue_sichtbarkeit() {
    this.picker.menue_sichtbarkeit()
  }

  protected readonly environment = environment;
}
