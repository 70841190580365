import {AfterViewInit, Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {Observable, zip} from 'rxjs';
import {SpeiseplanService} from '../../../../obs.speiseplan/services/speiseplan.service';
import {BestellungService} from '../../../../../services/bestellung/bestellung.service';
import * as moment from 'moment';
import {Router} from '@angular/router';
import {KundeService} from '../../../../../services/kunden/kunde.service';
import {environment} from '../../../../../environments/environment';
import {SpeiseplanMenuService} from '../../../../obs.speiseplan/services/speiseplanMenu.service';

@Component({
    selector: 'expressStorno-dialog',
    templateUrl: './expressStorno-dialog.component.html',
    styleUrls: ['./expressStorno-dialog.component.css'],
})
export class ExpressStornoDialogComponent implements OnInit {

    public apiZeitraum
    public speiseplanBestellung
    @ViewChild('zeitraumPicker') zeitraumPicker
    public freischalten = false
    public errorMsg = false
    public mobil

    constructor(
      @Inject(MAT_DIALOG_DATA) public data,
      private speiseplanService: SpeiseplanService,
      private bestellungService: BestellungService,
      private kundeService: KundeService,
      private router: Router,
      private dialog: MatDialog,
      private speiseplanMenuService: SpeiseplanMenuService,
    ) {}

    ngOnInit(){
      this.mobil = environment.type != 'web' ? true : false
      zip(
        this.speiseplanService.speiseplan_zeitraum(),
      ).subscribe((response:any) => {
        this.apiZeitraum = response[0]
        this.bestellungService.bestellung_kunde(this.data.kunde.bestellerId, response[0].von, response[0].bis).subscribe(bestellung => {
          this.speiseplanBestellung = bestellung
        })
      })
    }

    public expressStonobestaetigen(){
      this.errorMsg = false
      zip (
        this.bestellungService.bestellung_kunde(this.data.kunde.bestellerId, this.zeitraumPicker.zeitraum.von, this.zeitraumPicker.zeitraum.bis),
        this.speiseplanService.speiseplan_kunde(this.data.kunde.bestellerId, this.apiZeitraum.von, this.apiZeitraum.bis),
        this.kundeService._kunde_get()
      ).subscribe((response:any) => {
        this.bestellungService._menutexte = response[0].menuetexte
        let bestellTag:any
        let bestellungsTage = []
        for (bestellTag in response[0].kunden[this.data.kunde.bestellerId].tage){
          let suche:any = Object.entries(response[1].kunden[localStorage.getItem('active')].speisePlanTage).find(([key, value]) => key == bestellTag)
          let menuId:any
          let bestellungAngepasst = false
          let gesuchteMenus = Object.values(suche[1].tagesMenues)
          let bestellMenues: any = Object.values(response[0].kunden[this.data.kunde.bestellerId].tage[bestellTag].bestellMenues)
          for (menuId of bestellMenues) {
            if (menuId.mengeAlt != 0) {
              let menu:any
              for (menu of gesuchteMenus){
                if (menu.menueTextId == menuId.menueTextId){
                  if (!moment.default(menu.abbestellschluss).isBefore(moment.default()) &&
                      !this.zustellpausenCheck(response[2], moment.default(menu.abbestellschluss)) &&
                      !this.speiseplanMenuService.speiseplanNurBestellteMenues(this.data.mandant, menuId)){
                    menuId.mengeNeu = 0
                    bestellungAngepasst = true
                  } else {
                    menuId.mengeNeu = menuId.mengeAlt
                  }
                }
              }
            }
          }
          if (bestellungAngepasst){
            bestellungsTage.push(response[0].kunden[this.data.kunde.bestellerId].tage[bestellTag])
          }
        }
        if (bestellungsTage.length != 0){
          for (let entfernen of bestellungsTage){
            this.bestellungService.bestellung_setzen(entfernen)
          }
          if (this.router.url == '/warenkorb'){
            this.bestellungService.neueBestellungen.next(true)
            this.bestellungService._bestellungen_anzahl.next(1)
            this.bestellungService.warenkorb_update.next(true)
            this.router.navigateByUrl('/speiseplan-kunde', {skipLocationChange: true}).then(() => {
              this.router.navigateByUrl('/warenkorb')
            });
            this.dialog.closeAll()
          } else {
            this.dialog.closeAll()
            this.router.navigateByUrl('warenkorb')
          }
        } else {
          this.errorMsgAusloesen()
        }
      })
    }

    public sucheBestellungen(zeitraum){
      let frei = false
      this.bestellungService.bestellung_kunde(this.data.kunde.bestellerId, zeitraum.von, zeitraum.bis).subscribe((bestellung:any) => {
        let bestellTag:any
        for (bestellTag in bestellung.kunden[this.data.kunde.bestellerId].tage){
          let menuId:any
          for (menuId of Object.values(bestellung.kunden[this.data.kunde.bestellerId].tage[bestellTag].bestellMenues)) {
            if (menuId.mengeAlt != 0) {
              frei = true
            }
          }
        }
        this.freischalten = frei ? true : false
      })
    }

    public zustellpausenCheck(kunde, datum){
      for (var obj in kunde.zustellPausen) {
        var von = kunde.zustellPausen[obj].von;
        var bis = kunde.zustellPausen[obj].bis;
        if (bis !== '1970-01-01' && datum <= moment.default(bis) && datum >= moment.default(von)) {
          return true
        }
        if (bis == '1970-01-01' && datum >= moment.default(von)) {
          return true
        }
        return false
      }
    }

    public errorMsgAusloesen(){
      this.errorMsg = true
      setTimeout(() => {
        this.errorMsg = false
      }, 3000)
    }
}
