import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'standart-dialog',
  templateUrl:  './standart-dialog.components.html',
  styleUrls: ['./standart-dialog.components.css']
})
export class StandartDialogComponent implements OnInit {
  title: string;
  text: string;

  constructor(
    public dialogRef: MatDialogRef<StandartDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel
  ) {}

  ngOnInit() {
    // Update view with given values
    this.title = this.data.title;
    this.text = this.data.text;
  }

}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class ConfirmDialogModel {

  constructor(public title: string, public text: string) {
  }
}
