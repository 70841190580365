import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {BehaviorSubject, Subscription, zip} from 'rxjs';
import { BestellungService } from 'services/bestellung/bestellung.service';
import { DialogService } from 'services/prozesse/dialog.service';
import { KundeService } from 'services/kunden/kunde.service';
import { MandantenService } from 'services/mandanten/mandanten.service';
import { SpeiseplanService } from 'submodul/obs.speiseplan/services/speiseplan.service';
import { MitteilungsService } from 'services/prozesse/mitteilung.service';
import * as moment from 'moment/moment';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {BenachrichtigungService} from '../../../services/prozesse/benachrichtigung.service';
import {SeitenleisteService} from '../../../submodul/obs.seitenleiste/seitenleiste.service';
import {SpeiseplanDetailsComponent} from '../../../submodul/obs.speiseplan/einzelbesteller/speiseplanDetails/speiseplanDetails.component';
import {SeitenleisteInhalt} from '../../../submodul/obs.seitenleiste/seitenleisteInhalt.class';

@Component({
	selector: 'web-speiseplan-kunde',
	templateUrl: './web-speiseplan-kunde.component.html',
	styleUrls: ['../../../submodul/obs.speiseplan/einzelbesteller/speiseplanDarstellung/speiseplanDarstellungWBP.css'],
})

export class WebSpeiseplanKundeComponent implements OnInit, OnDestroy {

	@ViewChild('speiseplan_kalender') speiseplan_kalender
	@ViewChild('kundemitteilungen') mitteilungen: ElementRef
	public mandant = null
	public kunde = null
	public speiseplan: any
	public config
	public config_subscribtion: Subscription
	public modus
	public message: any
	public zeitraum: any
	public pdf_plaene
	public einrichtungsinfo
  public menu = null
  public menu_id
  public date
  public menuDetailsObserver: BehaviorSubject<boolean | object> = new BehaviorSubject(false)
  public speiseplanKunde
  public timeout: any
  public apiZeitraum
  public darstellung

	constructor(
		private dialog_service: DialogService,
		private kunden_service: KundeService,
		private mandant_service: MandantenService,
		private speiseplan_service: SpeiseplanService,
		private bestellung_service: BestellungService,
		private message_service: MitteilungsService,
    private benachrichtigung_service: BenachrichtigungService,
		private router: Router,
		public dialog: MatDialog,
		public pdf: MatDialog,
    private seitenleistenService: SeitenleisteService
	) {
    moment.locale('de');
		this.dialog_service.app_menu = true
	}

	ngOnInit() {
    zip(
      this.speiseplan_service.speiseplan_zeitraum(),
      this.mandant_service.mandant_get(),
      this.kunden_service._kunde_get()
    ).subscribe(apiResponse => {
      this.apiZeitraum = apiResponse[0]
      this.mandant = apiResponse[1]
      this.kunde = apiResponse[2]
      localStorage.setItem('revision', this.kunde.revision)
      this.benachrichtigung_service.setze_benachrichtigungen(this.kunde)
      this.message = this.kunde.einrichtung.info
    })
		this.config_subscribtion = this.speiseplan_service.config.subscribe((config) => { this.config = config })
		let path = this.router.url
		this.dialog_service.activePage_get(path)
    this.date = moment.utc().format('YYYY-MM-DD')
    this.message = this.message_service.mitteilungen.getValue()
    this.kunden_service._kunde_get().subscribe((kunde) => {
      this.kunde = kunde
      this.kunden_service.kundeGuthaben.next(kunde.guthaben)
      localStorage.setItem('revision', kunde.revision)
      this.benachrichtigung_service.setze_benachrichtigungen(kunde)
      this.message = kunde.einrichtung.info
      this.darstellung = window.screen.availWidth >= 1500 ? kunde.speiseplanDarstellung : 2;
    })
	}

	ngOnDestroy() {
		if (!this.config_subscribtion) this.speiseplan_service.config.unsubscribe()
	}

	speiseplan_laden(zeitraum) {
    if (zeitraum.length != 0){
      this.zeitraum = zeitraum
      this.modus = this.speiseplan_kalender.modus
      let speiseplan_response = this.speiseplan_service.speiseplan_kunde(this.kunde.bestellerId, zeitraum.start, zeitraum.ende)
      let bestellung_response = this.bestellung_service.bestellung_kunde(this.kunde.bestellerId, zeitraum.start, zeitraum.ende)
      speiseplan_response.subscribe((value) => {
        this.pdf_plaene = value.splanPdfs
      })
      zip(speiseplan_response, bestellung_response).subscribe((response: any) => {
        this.speiseplanKunde = response[0]
        this.speiseplan = {
          bestellschluss: response[0].bestellschlussMsgs,
          menu: response[0].kunden[this.kunde.kundeId].speisePlanTage,
          menutexte: response[0].menuetexte,
          bestellung: response[1].kunden[this.kunde.bestellerId],
          feiertage: this.mandant.feiertage,
          zustellpause: Object.keys(this.kunde.zustellPausen).length > 0 ? this.kunde.zustellPausen : null,
        }
        if (this.mandant.sonderKonfiguration['nurBestelltSichtbar']){
          this.speiseplan = this.speiseplan_service.nurbestellteMenus(this.mandant, this.speiseplan)
        }
        let menu:any
        for (menu of Object.entries(this.speiseplan.menu)){
          if (this.date == menu[0]){
            this.menu_details_aktualisieren(Object.entries(menu[1].tagesMenues)[0][1])
          } else {
            if (menu[0] == moment.utc().format('YYYY-MM-DD')){
              this.menu_details_aktualisieren(Object.entries(menu[1].tagesMenues)[0][1])
            }
          }
        }
        this.einrichtungsinfo = this.kunde.einrichtung.info
        this.bestellung_service._menutexte = Object.assign(this.bestellung_service._menutexte, response[1].menuetexte)
      }, (error) => {
        this.message_service.setMitteilung('8802')
      })
    } else {
      this.message_service.setMitteilung('8202')
    }
	}

	menu_details_aktualisieren(menu) {
    this.menu_id = menu.menueTextId
		menu['bezeichnung'] = this.speiseplan.menutexte[menu.menueTextId].bezeichnung
		menu['menueText'] = this.speiseplan.menutexte[menu.menueTextId].menueText
		menu['naehrwerte'] = this.speiseplan.menutexte[menu.menueTextId].naehrwerte
    this.menuDetailsObserver.next(menu)
    this.seitenleistenService.updateSeitenleiste(new SeitenleisteInhalt(
      SpeiseplanDetailsComponent, {
        mandant: this.mandant,
        speiseplanDetailsObserver: this.menuDetailsObserver
      })
    )
	}
}
