import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {RegistrierungService} from '../../../../../services/registrierung/registrierung.service';

@Component({
  selector: 'leistungsbeginnDatum',
  templateUrl: './leistungsbeginnDatum.component.html',
  styleUrls: ['./leistungsbeginnDatum.component.css']
})
export class LeistungsbeginnDatumComponent implements OnInit {
  @Input() mandant
  @Input() public formGroup: UntypedFormGroup
  @Input() feldConfig
  @Input() tabIndex
  @HostBinding('className') componentClass: string;

  constructor(
    public registrationService: RegistrierungService
  ) { }

  ngOnInit(): void {
    this.componentClass = this.feldConfig.name;
  }

  public changeDatum(datum){
    this.formGroup.controls['leistungsBeginn'].setValue(datum.target.value)
  }
}
