import {Component, Inject, AfterViewInit} from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'gesetz-dialog',
  templateUrl: './gesetz-dialog.component.html',
  styleUrls: ['./gesetz-dialog.component.css']
})
export class GesetzDialogComponent implements AfterViewInit{

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
		public dialog: MatDialog,
  ) { }
  public text

  ngAfterViewInit() {
    const element = document.getElementById('matDialogContent');
    element.scrollIntoView();
  }

  public closeDialog(){
    this.dialog.closeAll()
  }
}
