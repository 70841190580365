import {ViewContainerRef} from '@angular/core';
import {FormGroup} from '@angular/forms';
import * as moment from 'moment'
import {
  LeistungsbeginnBundeslandComponent
} from '../../submodul/obs.shared/fragmente/formular-felder/leistungsbeginnBundesland/leistungsbeginnBundesland.component';
import {
  LeistungsbeginnDatumComponent
} from '../../submodul/obs.shared/fragmente/formular-felder/leistungsbeginnDatum/leistungsbeginnDatum.component';

export class RegistrierungsLeistungsBeginnRegel {

  public optionen = []
  public date
  public year

  constructor(
    private viewContainerRef: ViewContainerRef,
    private mandant
  ){
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    this.year = today.getFullYear();
    this.date = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    this.optionen.push({"land": 'Heute', "datum": (this.year + '-' + mm + '-' + dd)})
  }

  public init(componentRefStack, formGroup: FormGroup, feldConfig, command){
    componentRefStack['leistungsBeginn'].destroy()
    if (command == 'land'){
      this.optionenErstellenLand()
      feldConfig['leistungsBeginn']['options'] = this.optionen
      componentRefStack['leistungsBeginn'] = this.viewContainerRef.createComponent(LeistungsbeginnBundeslandComponent)
      componentRefStack['leistungsBeginn'].instance.mandant = this.mandant
      componentRefStack['leistungsBeginn'].instance.formGroup = formGroup
      componentRefStack['leistungsBeginn'].instance.feldConfig = feldConfig['leistungsBeginn']
    }
    if (command == 'datum'){
      this.optionenErstellenDatum()
      feldConfig['leistungsBeginn']['options'] = this.optionen
      componentRefStack['leistungsBeginn'] = this.viewContainerRef.createComponent(LeistungsbeginnDatumComponent)
      componentRefStack['leistungsBeginn'].instance.mandant = this.mandant
      componentRefStack['leistungsBeginn'].instance.formGroup = formGroup
      componentRefStack['leistungsBeginn'].instance.feldConfig = feldConfig['leistungsBeginn']
    }
  }

  public optionenErstellenLand(){
    for (let option of this.mandant.sonderKonfiguration.leistungsbeginn){
      let year, month, day
      if (option[this.year]){
        [year, month, day] = option[this.year].split('-').map(Number);
      } else {
        for (let i = 1; i < option; i++) {
          const aktuellesDatum = new Date(option[i].split('-').map(Number));
          const jetzt = new Date();
          if (aktuellesDatum > jetzt) {
            [year, month, day] = option[i].split('-').map(Number);
            break;
          }
        }
      }
      if (new Date(year, month - 1, day) > this.date){
        this.optionen.push({"land" : option.land, "datum": option[this.year]})
      } else {
        if (option[this.year + 1]){
          this.optionen.push({"land" : option.land, "datum": option[this.year + 1]})
        }
      }
    }
  }

  public optionenErstellenDatum(){
    const datumListe: string[] = [];
    const heute = moment.default();

    for (let i = 0; i < 3; i++) {
      const mitteMonat = moment.default().date(15).add(i, 'months');
      const startMonat = moment.default().startOf('month').add(i, 'months');
      if (mitteMonat.isAfter(heute)) {
        datumListe.push(mitteMonat.format('YYYY-MM-DD'));
      }
      if (startMonat.isAfter(heute)) {
        datumListe.push(startMonat.format('YYYY-MM-DD'));
      }
    }
    if (datumListe.length > 5){
      datumListe.pop()
    }
    this.optionen = [...new Set(datumListe)].sort();
  }
}
