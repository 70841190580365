<div *ngIf="mandant" id="speiseplanOffentlich">
  <mat-card id="speiseplan-kunde-header">
    <speiseplan-kalender
      #speiseplan_kalender
      [mandant]="mandant"
      [apiZeitraum]="api_zeitraum"
      (zeitraum_update)="speiseplan_laden($event)"
    ></speiseplan-kalender>
    <web-speiseplan-header [kunde]="kunde" [mandant]="mandant" [zeitraum]="zeitraum" [modus]="modus" [speiseplan]="speiseplan" [einrichtungsinfo]="einrichtungsinfo" [pdf_plaene]="pdf_plaene"></web-speiseplan-header>
  </mat-card>
  <div id="speiseplan-kunde" *ngIf="speiseplan">
    <div speiseplan-darstellung [speiseplan]="speiseplan" [darstellung]="darstellung" [modus]="modus" [tag]="speiseplan.menu | keyvalue">
      <div *ngFor="let tag of speiseplan.menu | keyvalue" class="speiseplan-tag">
        <speiseplanTagLabel class="splanLabel" [speiseplan]="speiseplan" [tag]="tag" [darstellung]="darstellung"></speiseplanTagLabel>
        <speiseplanTagContent
          speiseplanTag
          [speiseplan_tag]="tag"
          [darstellung]="darstellung"
          [modus]="modus"
          [mandant]="mandant"
          [kunde]="kunde"
          [tag]="tag"
          [speiseplan]="speiseplan"
        ></speiseplanTagContent>
      </div>
    </div>
  </div>
</div>
