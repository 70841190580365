import {Component, Inject, OnInit} from '@angular/core';
import {MitteilungenService} from '../mitteilungen.service';
import {KundeService} from '../../../../../services/kunden/kunde.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'mitteilungen-dialog',
    templateUrl: './mitteilungen-dialog.component.html',
    styleUrls: ['./mitteilungen-dialog.component.css'],
})
export class MitteilungenDialogComponent implements OnInit {

  public mitteilungen
  public neu
  public kunde
  public da = true

  constructor(
    public mitteilungenService: MitteilungenService,
    private kundeService: KundeService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(){
    this.kundeService._kunde_get().subscribe(kunde => {
      this.kunde = kunde
      this.mitteilungenService.holeMitteilungen()
      this.mitteilungen = kunde ? this.mitteilungenService.mitteilungen_kunde.value : this.mitteilungenService.mitteilungen_global.value
      this.neu = this.mitteilungenService.abweichung
    })
  }
}
